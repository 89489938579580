import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Remove, Delete } from "@mui/icons-material";
import {
  Allergens,
  Diet_Type,
  DineInCategories,
  DineInItem,
  DineInSection,
  Maybe,
  useAddEMenuMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";
import swal from "sweetalert";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ExtraHotIcon } from "../../components/icons/ExtraHotIcon";
import { HotIcon } from "../../components/icons/HotIcon";
import { MediumIcon } from "../../components/icons/MediumIcon";
import { MildIcon } from "../../components/icons/MildIcon";
import { nanoid } from "nanoid";
import { v4 as uuidv4 } from "uuid";

interface prop {
  editData: DineInItem | null;
  propertyId: string;
  handleEditClose: (reason: string) => void;
  open: boolean;
  categories: Array<DineInCategories | null> | null | undefined;
  sections: Array<DineInSection | null> | null | undefined;
  allergens: Array<Allergens | null> | null | undefined;
  eMenu: any;
  refetch: () => void;
  setOpen: (v: boolean) => void;
}

interface nestedProp {
  nestIndex: number;
  control: any;
  register: any;
}

const NestedItems = ({ nestIndex, control, register }: nestedProp) => {
  const { fields: itemFields, remove: itemRemove } = useFieldArray({
    control,
    name: `selections.${nestIndex}.selection_items`,
  });
  return (
    <>
      {itemFields.map((item, index) => {
        return (
          <>
            <Grid item md={6}>
              <TextField
                required
                fullWidth
                label="Item name"
                {...register(
                  `selections.${nestIndex}.selection_items.${index}.name`
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => itemRemove(index)}
                        edge="end"
                        sx={{ color: "#E6170A" }}
                      >
                        {<Delete />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                fullWidth
                label="Item price"
                {...register(
                  `selections.${nestIndex}.selection_items.${index}.price`
                )}
              />
            </Grid>
          </>
        );
      })}
    </>
  );
};

const ItemForm = ({
  editData,
  propertyId,
  handleEditClose,
  open,
  categories,
  sections,
  allergens,
  refetch,
  eMenu,
  setOpen,
}: prop) => {
  const Diet = [Diet_Type.Vegan, Diet_Type.NonVegan, Diet_Type.None];

  const ITEM_HEIGHT = 48;

  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

        width: 250,
      },
    },
  };
  const chilli = [
    {
      name: "MildIcon",
      icon: <MildIcon />,
      level: "Mild",
    },
    {
      name: "MediumIcon",
      icon: <MediumIcon />,
      level: "Medium",
    },
    {
      name: "HotIcon",
      icon: <HotIcon />,
      level: "Hot",
    },
    {
      name: "ExtraHotIcon",
      icon: <ExtraHotIcon />,
      level: "Extra Hot",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm<DineInItem>({
    defaultValues: {
      desc: editData?.desc || "",
      name: editData?.name || "",
      type: editData?.type,
      price: editData?.price,
      isVegan: editData?.isVegan,
      extraOptions: editData?.extraOptions,
      img: editData?.img || "",
      allergens: editData?.allergens,
      section: editData?.section,
      is_recommended: editData?.is_recommended || false,
      is_Activated: editData?.is_Activated || true,
      selections: editData?.selections,
    },
  });

  React.useEffect(() => {
    return reset(
      editData || {
        desc: "",
        name: "",
        type: "",
        price: 0,
        isVegan: Diet_Type.None,
        extraOptions: [],
        img: "",
        allergens: [],
        section: [],
        is_recommended: false,
        is_Activated: true,
        selections: [],
      }
    );
  }, [editData, reset]);

  const { fields, append, remove } = useFieldArray({
    name: "extraOptions",
    control,
  });

  const {
    fields: selectionFields,
    append: selectionAppend,
    remove: selectionRemove,
  } = useFieldArray({
    name: "selections",
    control,
  });

  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

  const [upsertEMenu] = useAddEMenuMutation();

  const [image, setImage] = React.useState<any>("");
  const [uploadImg, setUploadImg] = React.useState<any>("");
  const [isDisable, setIsDisable] = React.useState(true);

  function findNamesByIds(allergens: any, ids: any) {
    return allergens
      .filter((allergen: any) => ids.includes(allergen.id))
      .map((allergen: any) =>
        allergen.icon === "Customized" ? allergen.uploaded_icon : allergen.icon
      );
  }

  function isNanoID(item: string) {
    // nanoid generates a 21-character alphanumeric string
    const nanoidRegex = /^[a-zA-Z0-9_-]{21}$/;
    return nanoidRegex.test(item);
  }

  function isListOfNanoIDs(list: any) {
    if (!list || list.length <= 0) {
      return true;
    }
    return list.every(isNanoID);
  }

  const onSubmit = async (data: DineInItem) => {
    if (image && !uploadImg) {
      swal({
        text: "Image upload is not complete. Please upload an image before submitting.",
        icon: "error",
      });
      return;
    }
    const AllergensIconsNames = isListOfNanoIDs(data?.allergens)
      ? findNamesByIds(allergens, data?.allergens)
      : data?.allergens;

    const cleanData = JSON.parse(
      JSON.stringify(data, (name, val) => {
        if (name === "__typename") {
          delete val[name];
        } else {
          return val;
        }
      })
    );

    const eMenuItems = JSON.parse(
      JSON.stringify(eMenu?.items, (name, val) => {
        if (name === "__typename") {
          delete val[name];
        } else {
          return val;
        }
      })
    );

    const newItemList =
      eMenuItems
        ?.filter((item: DineInItem) => item?.id !== data.id)
        .map((item: DineInItem) => ({
          ...item,
          property_id: propertyId,
          desc: item?.desc || "",
          img: item?.img || "",
          name: item?.name || "",
          price: item?.price || 0,
          type: item?.type || "",
        })) || [];

    newItemList.push({
      ...cleanData,
      id: cleanData.id || nanoid(),
      allergens: AllergensIconsNames,
      property_id: propertyId,
      img: image ? uploadImg : cleanData.img,
    });

    try {
      await upsertEMenu({
        variables: {
          eMenu: {
            id: eMenu?.id,
            name: eMenu?.name || "",
            property_id: propertyId || "",
            items: newItemList,
          },
        },
      });

      swal({
        text: "Item Added Successfully",
        icon: "success",
      });
      refetch();
      setOpen(false);
      setImage(null);
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const onCloseDialog = () => {
    handleEditClose("dialogClose");
    reset();
    setImage(null);
    setUploadImg(null);
  };
  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadImg(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Item" : "Add New Item"}
          </Typography>
          <IconButton color={"inherit"} onClick={onCloseDialog}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Service*/}
            <Grid item md={12}>
              <TextField
                required
                fullWidth
                label="Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                rows={5}
                multiline
                label="Enter Description"
                {...register("desc")}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                // type="number"
                min="0"
                required
                fullWidth
                label="Price"
                {...register("price", {
                  required: "Price is required!",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: "white", padding: "0 5px" }}
                >
                  Select Section
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("section")}
                  label="Section"
                  defaultValue={editData?.section ? editData?.section : []}
                >
                  {sections?.map((section, index) => (
                    <MenuItem key={index} value={section?.name}>
                      {section?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Multiple With Checkbox*/}
              {/* <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label" style={{ backgroundColor: "white", padding: "0 5px" }}> Select Section</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  {...register("section", {
                    
                  })}
                  defaultValue={editData?.section?editData?.section:[]}
                  // onChange={handleChange}
                  input={<OutlinedInput label="Select Section" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {sections?.map((section, index) => (
                    <MenuItem key={index} value={section?.name}>
                      <Checkbox checked={sections?.indexOf(section?.name || "") > -1} />
                      <ListItemText primary={section?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: "white", padding: "0 5px" }}
                >
                  Select Category
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("type", {
                    required: "Category is required",
                  })}
                  label="Category"
                  defaultValue={editData?.type || ""}
                >
                  {categories
                    ?.filter((category) => category?.name !== "Uncategorized")
                    ?.sort(
                      (a, b) => (a?.name as any).localeCompare(b?.name) as any
                    )
                    ?.map((category, index) => (
                      <MenuItem key={index} value={category?.name}>
                        {category?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: "white", padding: "0 5px" }}
                >
                  Select Type{" "}
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("isVegan")}
                  label="Type"
                  defaultValue={editData?.isVegan || Diet_Type.None}
                >
                  {Diet?.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: "white", padding: "0 5px" }}
                >
                  Select Allergen
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  multiple
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("allergens", {
                    // required: "allergens is required",
                  })}
                  label="Allergen"
                  defaultValue={
                    allergens
                      ?.filter((allergen) =>
                        editData?.allergens?.includes(
                          (allergen?.icon === "Customized"
                            ? allergen?.uploaded_icon
                            : allergen?.icon) as Maybe<string>
                        )
                      )
                      ?.map((allergen) => allergen?.id as Maybe<string>) ?? []
                  }
                >
                  {allergens?.map((allergen, index) => (
                    <MenuItem key={index} value={allergen?.id || ""}>
                      {allergen?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Spice Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("spice_level", {})}
                  label="Section Icon"
                  defaultValue={editData?.spice_level || ""}
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  {chilli.map((ic, index) => (
                    <MenuItem key={ic.name} value={ic.name}>
                      <ListItemIcon>{ic.icon}</ListItemIcon>
                      {ic.level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Controller
                    name={"is_recommended"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={props.value || false}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label="Chef's Recommended"
              />
            </Grid>
            {fields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  <Grid container spacing={2} mt={2} ml={0.1}>
                    <Grid item md={4}>
                      <TextField
                        required
                        fullWidth
                        label="Extras name"
                        {...register(`extraOptions.${index}.name`)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        required
                        fullWidth
                        label="Extras price"
                        {...register(`extraOptions.${index}.price`)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Button
                        sx={{ marginTop: "5px" }}
                        variant="outlined"
                        type="button"
                        startIcon={<Remove />}
                        onClick={() => remove(index)}
                      >
                        DELETE
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid />
            <Box>
              <Grid container spacing={2} mt={1} ml={0}>
                <Grid item md={6}>
                  <Button
                    variant="contained"
                    type="button"
                    size="large"
                    startIcon={<Add />}
                    onClick={() =>
                      append({
                        name: "",
                        price: 0,
                      })
                    }
                  >
                    Extras
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {selectionFields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  <Grid container spacing={2} mt={2} ml={0.1}>
                    <Grid item md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Selection name"
                        {...register(`selections.${index}.name`)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        required
                        fullWidth
                        defaultValue={0}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        label="Minimum Item Selection Limit"
                        type="number"
                        {...register(`selections.${index}.min_limit`, {
                          min: 0,
                        })}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        required
                        fullWidth
                        defaultValue={1}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        label="Maximum Item Selection Limit"
                        type="number"
                        {...register(`selections.${index}.limit`, {
                          min: 0,
                          validate: (v: any) => {
                            if (
                              parseInt(v) <
                              (getValues(`selections.${index}.min_limit`) || 0)
                            ) {
                              return false;
                            } else {
                              return true;
                            }
                          },
                        })}
                      />
                      {errors.selections?.[index]?.limit && (
                        <Typography sx={{ color: "red" }}>
                          Maximum Item Selection Limit should be greater than or
                          equal to Minimum Item Selection Limit
                        </Typography>
                      )}
                    </Grid>

                    <NestedItems
                      nestIndex={index}
                      control={control}
                      register={register}
                    />
                    <Grid item container md={12} justifyContent={"flex-end"}>
                      <Button
                        variant="text"
                        sx={{ color: "red", fontSize: "12px", mr: 2 }}
                        onClick={() => selectionRemove(index)}
                      >
                        Delete Selection
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          getValues() &&
                            setValue(`selections.${index}.selection_items`, [
                              ...(getValues(
                                `selections.${index}.selection_items`
                              ) || []),
                              { id: uuidv4(), name: "", price: 0 },
                            ]);
                        }}
                      >
                        Add Item
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}

            <Grid item md={12}>
              <Button
                type="button"
                size="large"
                fullWidth
                // startIcon={<Add />}
                sx={{ border: `1px dashed` }}
                onClick={() =>
                  selectionAppend({
                    id: uuidv4(),
                    name: "",
                    selection_items: [],
                  })
                }
              >
                Add Selection
              </Button>
            </Grid>

            <Grid item md={12} mb={2}>
              <Typography color="text.secondary" gutterBottom>
                Select Image
              </Typography>
              {loadingImage ? (
                <Box
                  sx={{
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "60px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                (editData?.img || image) && (
                  <Box>
                    <img
                      alt="Service"
                      width={"250px"}
                      src={image ? image : editData?.img}
                    />

                    {image && (
                      <Box
                        component={IconButton}
                        onClick={() => setImage(null)}
                      >
                        <Close />
                      </Box>
                    )}
                  </Box>
                )
              )}
              <Button variant="outlined">
                <label>
                  {editData ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty && isDisable}
              loading={false}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ItemForm;
