import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: any;
};

export type About = {
  __typename?: "About";
  desc: Scalars["String"];
  img: Scalars["String"];
};

export type AboutInput = {
  desc: Scalars["String"];
  img: Scalars["String"];
};

export type Allergens = {
  __typename?: "Allergens";
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type AllergensInput = {
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export enum AuthMethod {
  Combo = "COMBO",
  Email = "EMAIL",
  Phone = "PHONE",
  Room = "ROOM",
}

export type Booking = {
  __typename?: "Booking";
  amount: Scalars["Int"];
  aptmt_date_time: Scalars["String"];
  assigned_to?: Maybe<Array<Maybe<Scalars["String"]>>>;
  guest_email?: Maybe<Scalars["String"]>;
  guest_name: Scalars["String"];
  guest_phone?: Maybe<Scalars["String"]>;
  guest_room_number?: Maybe<Scalars["String"]>;
  guest_type?: Maybe<Scalars["String"]>;
  hours: Scalars["String"];
  id: Scalars["ID"];
  property_id: Scalars["String"];
  status: Scalars["String"];
  treatments?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type BookingInput = {
  amount: Scalars["Int"];
  aptmt_date_time: Scalars["String"];
  assigned_to?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  guest_email?: InputMaybe<Scalars["String"]>;
  guest_name: Scalars["String"];
  guest_phone?: InputMaybe<Scalars["String"]>;
  guest_room_number?: InputMaybe<Scalars["String"]>;
  guest_type?: InputMaybe<Scalars["String"]>;
  hours: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  property_id: Scalars["String"];
  status: Scalars["String"];
  treatments?: InputMaybe<Array<InputMaybe<BookingTreatmentInput>>>;
};

export type BookingTreatmentInput = {
  assigned_to?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  duration: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
};

export type Cart = {
  __typename?: "Cart";
  dineInItem_id: Scalars["String"];
  dineInItem_name: Scalars["String"];
  dineInItem_price: Scalars["Float"];
  extras?: Maybe<Array<Maybe<ExtraOptions>>>;
  quantity: Scalars["Int"];
  selections?: Maybe<Array<Maybe<Selection>>>;
};

export type CartInput = {
  dineInItem_id: Scalars["String"];
  dineInItem_name: Scalars["String"];
  dineInItem_price: Scalars["Float"];
  extras?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  quantity: Scalars["Int"];
  selections?: InputMaybe<Array<InputMaybe<SelectionInput>>>;
};

export type Condiments = {
  condimentId?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

export enum Days {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
}

export type Department = {
  __typename?: "Department";
  department?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

export enum Diet_Type {
  NonVegan = "NonVegan",
  None = "None",
  Vegan = "Vegan",
}

export type DineInCategories = {
  __typename?: "DineInCategories";
  desc?: Maybe<Scalars["String"]>;
  icon: Scalars["String"];
  id: Scalars["ID"];
  is_Activated?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type DineInCategoriesInput = {
  desc?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type DineInItem = {
  __typename?: "DineInItem";
  allergens?: Maybe<Array<Maybe<Scalars["String"]>>>;
  availabletime?: Maybe<Scalars["String"]>;
  desc: Scalars["String"];
  extraOptions?: Maybe<Array<Maybe<ExtraOptions>>>;
  id: Scalars["ID"];
  img: Scalars["String"];
  isVegan?: Maybe<Diet_Type>;
  is_Activated?: Maybe<Scalars["Boolean"]>;
  is_recommended?: Maybe<Scalars["Boolean"]>;
  menu_item_id?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  price: Scalars["Float"];
  property_id: Scalars["String"];
  section?: Maybe<Array<Maybe<Scalars["String"]>>>;
  selections?: Maybe<Array<Maybe<Selection>>>;
  sequence?: Maybe<Scalars["Int"]>;
  spice_level?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type DineInItemInput = {
  allergens?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  availabletime?: InputMaybe<Scalars["String"]>;
  desc: Scalars["String"];
  extraOptions?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  isVegan?: InputMaybe<Diet_Type>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  is_recommended?: InputMaybe<Scalars["Boolean"]>;
  menu_item_id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  price: Scalars["Float"];
  property_id: Scalars["String"];
  section?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  selections?: InputMaybe<Array<InputMaybe<SelectionInput>>>;
  sequence?: InputMaybe<Scalars["Int"]>;
  spice_level?: InputMaybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type DineInSection = {
  __typename?: "DineInSection";
  default_category?: Maybe<Scalars["String"]>;
  desc?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  icon: Scalars["String"];
  id: Scalars["ID"];
  is_Activated?: Maybe<Scalars["Boolean"]>;
  message?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  start_time?: Maybe<Scalars["String"]>;
  time_based?: Maybe<Scalars["Boolean"]>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type DineInSectionInput = {
  default_category?: InputMaybe<Scalars["String"]>;
  desc?: InputMaybe<Scalars["String"]>;
  end_time?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  start_time?: InputMaybe<Scalars["String"]>;
  time_based?: InputMaybe<Scalars["Boolean"]>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type Dining = {
  __typename?: "Dining";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  service_id?: Maybe<Scalars["Int"]>;
};

export type DiningInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  service_id?: InputMaybe<Scalars["Int"]>;
};

export type DirectionsAndContact = {
  __typename?: "DirectionsAndContact";
  address: Scalars["String"];
  email: Scalars["String"];
  img: Scalars["String"];
  phone: Scalars["String"];
};

export type DirectionsAndContactInput = {
  address: Scalars["String"];
  email: Scalars["String"];
  img: Scalars["String"];
  phone: Scalars["String"];
};

export type EMenu = {
  __typename?: "EMenu";
  auth_method?: Maybe<AuthMethod>;
  department_id?: Maybe<Scalars["Int"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  interactive?: Maybe<Scalars["Boolean"]>;
  items?: Maybe<Array<Maybe<DineInItem>>>;
  name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  room?: Maybe<Scalars["String"]>;
};

export type EMenuInput = {
  auth_method?: InputMaybe<AuthMethod>;
  department_id?: InputMaybe<Scalars["Int"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  interactive?: InputMaybe<Scalars["Boolean"]>;
  items?: InputMaybe<Array<InputMaybe<DineInItemInput>>>;
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type ExtraOptions = {
  __typename?: "ExtraOptions";
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type ExtraOptionsInput = {
  name: Scalars["String"];
  price?: InputMaybe<Scalars["Float"]>;
};

export type Feedback = {
  __typename?: "Feedback";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type FeedbackInput = {
  feedback: Scalars["String"];
  feedback_type_id: Scalars["Int"];
  property_id: Scalars["String"];
  room_id: Scalars["String"];
};

export type Guest = {
  __typename?: "Guest";
  guest_id?: Maybe<Scalars["String"]>;
  guest_name?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
};

export type GuestInput = {
  guest_id?: InputMaybe<Scalars["String"]>;
  room?: InputMaybe<Scalars["String"]>;
};

export type HotelService = {
  __typename?: "HotelService";
  desc?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  img?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  sub_properties?: Maybe<Array<Scalars["String"]>>;
  sub_services?: Maybe<Array<Maybe<SubService>>>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type HotelServiceInput = {
  desc?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  img?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  sub_properties?: InputMaybe<Array<Scalars["String"]>>;
  sub_services?: InputMaybe<Array<InputMaybe<SubServiceInput>>>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type HotlyncServices = {
  __typename?: "HotlyncServices";
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type HotlyncServicesInput = {
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type Invoice = {
  __typename?: "Invoice";
  bill_balance?: Maybe<Scalars["String"]>;
  bill_items?: Maybe<Array<Maybe<InvoiceItems>>>;
};

export type InvoiceItems = {
  __typename?: "InvoiceItems";
  description?: Maybe<Scalars["String"]>;
  item_amount?: Maybe<Scalars["String"]>;
  item_date?: Maybe<Scalars["String"]>;
  item_time?: Maybe<Scalars["String"]>;
};

export type Is_Activated = {
  __typename?: "Is_Activated";
  about?: Maybe<Scalars["Boolean"]>;
  artNdesign?: Maybe<Scalars["Boolean"]>;
  dining?: Maybe<Scalars["Boolean"]>;
  directory?: Maybe<Scalars["Boolean"]>;
  events?: Maybe<Scalars["Boolean"]>;
  hotel_rooms?: Maybe<Scalars["Boolean"]>;
  hotel_services?: Maybe<Scalars["Boolean"]>;
  neighbourhood?: Maybe<Scalars["Boolean"]>;
  safetyNsecurity?: Maybe<Scalars["Boolean"]>;
};

export type Is_ActivatedInput = {
  about?: InputMaybe<Scalars["Boolean"]>;
  artNdesign?: InputMaybe<Scalars["Boolean"]>;
  dining?: InputMaybe<Scalars["Boolean"]>;
  directory?: InputMaybe<Scalars["Boolean"]>;
  events?: InputMaybe<Scalars["Boolean"]>;
  hotel_rooms?: InputMaybe<Scalars["Boolean"]>;
  hotel_services?: InputMaybe<Scalars["Boolean"]>;
  neighbourhood?: InputMaybe<Scalars["Boolean"]>;
  safetyNsecurity?: InputMaybe<Scalars["Boolean"]>;
};

export type Itinerary = {
  __typename?: "Itinerary";
  description: Scalars["String"];
  duration: Scalars["String"];
  end_date: Scalars["String"];
  guest_list?: Maybe<Array<Maybe<Guest>>>;
  id: Scalars["ID"];
  is_for_all?: Maybe<Scalars["Boolean"]>;
  is_repeated?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  start_date: Scalars["String"];
  time: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  venue?: Maybe<Scalars["String"]>;
};

export type ItineraryInput = {
  description: Scalars["String"];
  duration: Scalars["String"];
  end_date: Scalars["String"];
  guest_list?: InputMaybe<Array<InputMaybe<GuestInput>>>;
  id?: InputMaybe<Scalars["ID"]>;
  is_for_all?: InputMaybe<Scalars["Boolean"]>;
  is_repeated?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  start_date: Scalars["String"];
  time: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
  venue?: InputMaybe<Scalars["String"]>;
};

export type ItineraryTypes = {
  __typename?: "ItineraryTypes";
  id: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type ItineraryTypesInput = {
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type Layout = {
  __typename?: "Layout";
  active?: Maybe<Scalars["Boolean"]>;
  cardName: Scalars["String"];
  displayName?: Maybe<Scalars["String"]>;
  sequence: Scalars["Int"];
};

export type LayoutInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  cardName: Scalars["String"];
  displayName?: InputMaybe<Scalars["String"]>;
  sequence: Scalars["Int"];
};

export type MenuItems = {
  condiments?: InputMaybe<Array<InputMaybe<Condiments>>>;
  menuitemid?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

export type MetaData = {
  __typename?: "MetaData";
  about: About;
  artNdesign?: Maybe<About>;
  dining?: Maybe<Array<Maybe<Dining>>>;
  dining_image?: Maybe<Scalars["String"]>;
  dir_con: DirectionsAndContact;
  directory_description?: Maybe<Scalars["String"]>;
  events?: Maybe<About>;
  feedback_desc?: Maybe<Scalars["String"]>;
  has_order_service_id?: Maybe<Scalars["Boolean"]>;
  hotel_name: Scalars["String"];
  hotel_rooms?: Maybe<Array<Room>>;
  hotel_services?: Maybe<Array<HotelService>>;
  hotel_services_image?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  is_Activated?: Maybe<Is_Activated>;
  neighbourhood?: Maybe<About>;
  order_service_id?: Maybe<Scalars["Int"]>;
  promotion_desc?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  rooms_image?: Maybe<Scalars["String"]>;
  safetyNsecurity?: Maybe<About>;
};

export type MetaDataInput = {
  about: AboutInput;
  artNdesign?: InputMaybe<AboutInput>;
  dining_image?: InputMaybe<Scalars["String"]>;
  dir_con: DirectionsAndContactInput;
  directory_description?: InputMaybe<Scalars["String"]>;
  events?: InputMaybe<AboutInput>;
  feedback_desc?: InputMaybe<Scalars["String"]>;
  has_order_service_id?: InputMaybe<Scalars["Boolean"]>;
  hotel_name: Scalars["String"];
  hotel_services_image?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Is_ActivatedInput>;
  neighbourhood?: InputMaybe<AboutInput>;
  order_service_id?: InputMaybe<Scalars["Int"]>;
  promotion_desc?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  rooms_image?: InputMaybe<Scalars["String"]>;
  safetyNsecurity?: InputMaybe<AboutInput>;
};

export type Mutation = {
  __typename?: "Mutation";
  cancelOrder?: Maybe<Scalars["String"]>;
  changeOrderStatus?: Maybe<Scalars["String"]>;
  createFeedback?: Maybe<Response>;
  createSimphonyWithComboMeals?: Maybe<StatusResponse>;
  createSimphonyWithInvalidItem?: Maybe<StatusResponse>;
  createSimphonyWithInvalidProperty?: Maybe<StatusResponse>;
  deleteAllergens?: Maybe<Scalars["String"]>;
  deleteDineInCategory?: Maybe<Scalars["String"]>;
  deleteDineInSection?: Maybe<Scalars["String"]>;
  deleteDining?: Maybe<Scalars["String"]>;
  deleteEMenu?: Maybe<Scalars["String"]>;
  deleteEdible?: Maybe<Scalars["String"]>;
  deleteHotelService?: Maybe<Scalars["String"]>;
  deleteItinerary?: Maybe<Scalars["String"]>;
  deleteItineraryTypes?: Maybe<Scalars["String"]>;
  deleteOrder?: Maybe<Scalars["String"]>;
  deletePromotion?: Maybe<Scalars["String"]>;
  deleteProperty?: Maybe<Property>;
  deleteRole?: Maybe<Scalars["String"]>;
  deleteRoom?: Maybe<Scalars["String"]>;
  deleteSubProperty?: Maybe<Scalars["String"]>;
  deleteUser?: Maybe<Scalars["String"]>;
  requestService?: Maybe<Response>;
  requestServices?: Maybe<Response>;
  syncSimphonyPOSMenuItems?: Maybe<StatusResponse>;
  updateEdibleStatus?: Maybe<DineInItem>;
  updatePromotionStatus?: Maybe<Promotion>;
  upsertAllergens?: Maybe<Allergens>;
  upsertApiKey?: Maybe<Scalars["String"]>;
  upsertBooking?: Maybe<Booking>;
  upsertDineInCategory?: Maybe<DineInCategories>;
  upsertDineInSection?: Maybe<DineInSection>;
  upsertDining?: Maybe<Scalars["String"]>;
  upsertEMenu?: Maybe<EMenu>;
  upsertEMenuOrder?: Maybe<OrderResponse>;
  upsertEdible?: Maybe<DineInItem>;
  upsertHotelService?: Maybe<Scalars["String"]>;
  upsertHotlyncServices?: Maybe<HotlyncServices>;
  upsertItinerary?: Maybe<Itinerary>;
  upsertItineraryTypes?: Maybe<ItineraryTypes>;
  upsertMetaData?: Maybe<Scalars["String"]>;
  upsertOrder?: Maybe<OrderResponse>;
  upsertPromotion?: Maybe<Promotion>;
  upsertProperty?: Maybe<Property>;
  upsertRole?: Maybe<Role>;
  upsertRoom?: Maybe<Room>;
  upsertSubProperty?: Maybe<SubProperty>;
  upsertUser?: Maybe<User>;
  verifyGuest?: Maybe<Response>;
};

export type MutationCancelOrderArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationChangeOrderStatusArgs = {
  id: Scalars["String"];
  orderStatus: Status;
  propertyID: Scalars["String"];
};

export type MutationCreateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};

export type MutationCreateSimphonyWithComboMealsArgs = {
  propertyID: Scalars["String"];
};

export type MutationCreateSimphonyWithInvalidItemArgs = {
  propertyID: Scalars["String"];
};

export type MutationCreateSimphonyWithInvalidPropertyArgs = {
  propertyID: Scalars["String"];
};

export type MutationDeleteAllergensArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDineInCategoryArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDineInSectionArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDiningArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteEMenuArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type MutationDeleteEdibleArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteHotelServiceArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteItineraryArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteItineraryTypesArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteOrderArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeletePromotionArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeletePropertyArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteRoleArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type MutationDeleteRoomArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteSubPropertyArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationRequestServiceArgs = {
  serviceInput: ServiceInput;
};

export type MutationRequestServicesArgs = {
  servicesInput?: InputMaybe<Array<ServiceInput>>;
};

export type MutationSyncSimphonyPosMenuItemsArgs = {
  propertyID: Scalars["String"];
};

export type MutationUpdateEdibleStatusArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
};

export type MutationUpdatePromotionStatusArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
};

export type MutationUpsertAllergensArgs = {
  allergensInput?: InputMaybe<AllergensInput>;
};

export type MutationUpsertApiKeyArgs = {
  apiKey: Scalars["String"];
  propertyID: Scalars["String"];
};

export type MutationUpsertBookingArgs = {
  bookingInput?: InputMaybe<BookingInput>;
};

export type MutationUpsertDineInCategoryArgs = {
  dineInCategoriesInput?: InputMaybe<DineInCategoriesInput>;
};

export type MutationUpsertDineInSectionArgs = {
  dineInSectionInput?: InputMaybe<DineInSectionInput>;
};

export type MutationUpsertDiningArgs = {
  diningInput: DiningInput;
};

export type MutationUpsertEMenuArgs = {
  eMenu: EMenuInput;
};

export type MutationUpsertEMenuOrderArgs = {
  id: Scalars["ID"];
  orderInput: OrderInput;
};

export type MutationUpsertEdibleArgs = {
  dineInItemInput: DineInItemInput;
};

export type MutationUpsertHotelServiceArgs = {
  hotelServiceInput: HotelServiceInput;
};

export type MutationUpsertHotlyncServicesArgs = {
  hotlyncServicesInput?: InputMaybe<HotlyncServicesInput>;
};

export type MutationUpsertItineraryArgs = {
  itineraryInput?: InputMaybe<ItineraryInput>;
};

export type MutationUpsertItineraryTypesArgs = {
  itineraryTypesInput?: InputMaybe<ItineraryTypesInput>;
};

export type MutationUpsertMetaDataArgs = {
  metaInput: MetaDataInput;
};

export type MutationUpsertOrderArgs = {
  orderInput: OrderInput;
};

export type MutationUpsertPromotionArgs = {
  promotionInput: PromotionInput;
};

export type MutationUpsertPropertyArgs = {
  propertyInput: PropertyInput;
};

export type MutationUpsertRoleArgs = {
  role: RoleInput;
};

export type MutationUpsertRoomArgs = {
  roomInput: RoomInput;
};

export type MutationUpsertSubPropertyArgs = {
  subProperty: SubPropertyInput;
};

export type MutationUpsertUserArgs = {
  userInput: UserInput;
};

export type MutationVerifyGuestArgs = {
  guestName: Scalars["String"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type Order = {
  __typename?: "Order";
  cart_items?: Maybe<Array<Cart>>;
  comment?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  external_guest_id?: Maybe<Scalars["String"]>;
  guest_id: Scalars["Int"];
  guest_name: Scalars["String"];
  guest_phone?: Maybe<Scalars["String"]>;
  hotel?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  location?: Maybe<Scalars["String"]>;
  order_id: Scalars["String"];
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
  status?: Maybe<Status>;
  total: Scalars["Float"];
  updated_at?: Maybe<Scalars["String"]>;
};

export type OrderInput = {
  cart_items?: InputMaybe<Array<CartInput>>;
  comment?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["String"]>;
  external_guest_id?: InputMaybe<Scalars["String"]>;
  guest_id: Scalars["Int"];
  guest_name: Scalars["String"];
  guest_phone?: InputMaybe<Scalars["String"]>;
  hotel?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  location?: InputMaybe<Scalars["String"]>;
  order_id?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
  status?: InputMaybe<Status>;
  total: Scalars["Float"];
};

export type OrderResponse = {
  __typename?: "OrderResponse";
  code: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  order?: Maybe<Order>;
};

export type OtpResponse = {
  __typename?: "OtpResponse";
  code?: Maybe<Scalars["Int"]>;
  departments?: Maybe<Array<Maybe<Department>>>;
  message: Scalars["String"];
};

export type PermissionTypes = {
  __typename?: "PermissionTypes";
  allergens?: Maybe<Array<Maybe<Permissions>>>;
  dine_in_categories?: Maybe<Array<Maybe<Permissions>>>;
  dine_in_menu?: Maybe<Array<Maybe<Permissions>>>;
  facilities?: Maybe<Array<Maybe<Permissions>>>;
  hotel_info?: Maybe<Array<Maybe<Permissions>>>;
  itinerary?: Maybe<Array<Maybe<Permissions>>>;
  promotions?: Maybe<Array<Maybe<Permissions>>>;
  reports?: Maybe<Array<Maybe<Permissions>>>;
  rooms?: Maybe<Array<Maybe<Permissions>>>;
  services?: Maybe<Array<Maybe<Permissions>>>;
  settings?: Maybe<Array<Maybe<Permissions>>>;
};

export type PermissionTypesInput = {
  allergens?: InputMaybe<Array<InputMaybe<Permissions>>>;
  dine_in_categories?: InputMaybe<Array<InputMaybe<Permissions>>>;
  dine_in_menu?: InputMaybe<Array<InputMaybe<Permissions>>>;
  facilities?: InputMaybe<Array<InputMaybe<Permissions>>>;
  hotel_info?: InputMaybe<Array<InputMaybe<Permissions>>>;
  itinerary?: InputMaybe<Array<InputMaybe<Permissions>>>;
  promotions?: InputMaybe<Array<InputMaybe<Permissions>>>;
  reports?: InputMaybe<Array<InputMaybe<Permissions>>>;
  rooms?: InputMaybe<Array<InputMaybe<Permissions>>>;
  services?: InputMaybe<Array<InputMaybe<Permissions>>>;
  settings?: InputMaybe<Array<InputMaybe<Permissions>>>;
  user_management?: InputMaybe<Array<InputMaybe<Permissions>>>;
};

export enum Permissions {
  Activate = "ACTIVATE",
  Add = "ADD",
  AppSetting = "APP_SETTING",
  Delete = "DELETE",
  Edit = "EDIT",
  HotelInfo = "HOTEL_INFO",
  PriceEdit = "PRICE_EDIT",
  Rearrange = "REARRANGE",
  Sync = "SYNC",
}

export type Promotion = {
  __typename?: "Promotion";
  banner_image: Scalars["String"];
  description: Scalars["String"];
  end_date: Scalars["String"];
  id: Scalars["ID"];
  is_Activated?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  start_date: Scalars["String"];
};

export type PromotionInput = {
  banner_image: Scalars["String"];
  description: Scalars["String"];
  end_date: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  start_date: Scalars["String"];
};

export type Property = {
  __typename?: "Property";
  background_color?: Maybe<Scalars["String"]>;
  background_image?: Maybe<Scalars["String"]>;
  button_bg?: Maybe<Scalars["String"]>;
  by_dining?: Maybe<Scalars["String"]>;
  chat_api_url: Scalars["String"];
  chat_text?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  direct_simphony_pos_check?: Maybe<Scalars["Boolean"]>;
  domain?: Maybe<Scalars["String"]>;
  feedback_text?: Maybe<Scalars["String"]>;
  font?: Maybe<Scalars["String"]>;
  has_simphony_pos?: Maybe<Scalars["Boolean"]>;
  hide_all_menu?: Maybe<Scalars["Boolean"]>;
  hotlync_api_url?: Maybe<Scalars["String"]>;
  hotlync_password?: Maybe<Scalars["String"]>;
  hotlync_property_id?: Maybe<Scalars["String"]>;
  hotlync_username?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  img: Scalars["String"];
  layout?: Maybe<Array<Maybe<Layout>>>;
  list_service?: Maybe<Scalars["Boolean"]>;
  logo_tile_bg?: Maybe<Scalars["String"]>;
  logo_tile_transperancy?: Maybe<Scalars["Float"]>;
  name: Scalars["String"];
  navbar_active_background_color?: Maybe<Scalars["String"]>;
  navbar_active_title_color?: Maybe<Scalars["String"]>;
  navbar_background_color?: Maybe<Scalars["String"]>;
  navbar_title_color?: Maybe<Scalars["String"]>;
  palm_icon?: Maybe<Scalars["Boolean"]>;
  palm_text?: Maybe<Scalars["String"]>;
  pos_loc_ref?: Maybe<Scalars["String"]>;
  pos_rvc_ref?: Maybe<Scalars["String"]>;
  primary_color: Scalars["String"];
  primary_light: Scalars["String"];
  primary_text_color: Scalars["String"];
  restaurant_email?: Maybe<Array<Maybe<Scalars["String"]>>>;
  restaurant_link?: Maybe<Scalars["String"]>;
  restaurant_phone?: Maybe<Scalars["String"]>;
  schedule_request?: Maybe<Scalars["Boolean"]>;
  secondary_text_color: Scalars["String"];
  send_to_simphony?: Maybe<Scalars["Boolean"]>;
  sender_email?: Maybe<Scalars["String"]>;
  show_main_category?: Maybe<Scalars["Boolean"]>;
  show_talk_to_us?: Maybe<Scalars["Boolean"]>;
  simphony_pos_url?: Maybe<Scalars["String"]>;
  spa_id?: Maybe<Scalars["String"]>;
  star_rating?: Maybe<Scalars["Boolean"]>;
  tile_color?: Maybe<Scalars["String"]>;
  title_color?: Maybe<Scalars["String"]>;
  transperancy?: Maybe<Scalars["Float"]>;
  whatsapp_icon?: Maybe<Scalars["Boolean"]>;
};

export type PropertyInput = {
  background_color: Scalars["String"];
  background_image?: InputMaybe<Scalars["String"]>;
  button_bg?: InputMaybe<Scalars["String"]>;
  by_dining?: InputMaybe<Scalars["String"]>;
  chat_api_url: Scalars["String"];
  chat_text?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  currency: Scalars["String"];
  direct_simphony_pos_check?: InputMaybe<Scalars["Boolean"]>;
  domain: Scalars["String"];
  feedback_text?: InputMaybe<Scalars["String"]>;
  font?: InputMaybe<Scalars["String"]>;
  has_simphony_pos?: InputMaybe<Scalars["Boolean"]>;
  hide_all_menu?: InputMaybe<Scalars["Boolean"]>;
  hotlync_api_url: Scalars["String"];
  hotlync_password?: InputMaybe<Scalars["String"]>;
  hotlync_property_id?: InputMaybe<Scalars["String"]>;
  hotlync_username?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  layout?: InputMaybe<Array<InputMaybe<LayoutInput>>>;
  list_service?: InputMaybe<Scalars["Boolean"]>;
  logo_tile_bg?: InputMaybe<Scalars["String"]>;
  logo_tile_transperancy?: InputMaybe<Scalars["Float"]>;
  name: Scalars["String"];
  navbar_active_background_color?: InputMaybe<Scalars["String"]>;
  navbar_active_title_color?: InputMaybe<Scalars["String"]>;
  navbar_background_color?: InputMaybe<Scalars["String"]>;
  navbar_title_color?: InputMaybe<Scalars["String"]>;
  palm_icon?: InputMaybe<Scalars["Boolean"]>;
  palm_text?: InputMaybe<Scalars["String"]>;
  pos_loc_ref?: InputMaybe<Scalars["String"]>;
  pos_rvc_ref?: InputMaybe<Scalars["String"]>;
  primary_color: Scalars["String"];
  primary_light: Scalars["String"];
  primary_text_color: Scalars["String"];
  restaurant_email?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  restaurant_phone?: InputMaybe<Scalars["String"]>;
  schedule_request?: InputMaybe<Scalars["Boolean"]>;
  secondary_text_color: Scalars["String"];
  send_to_simphony?: InputMaybe<Scalars["Boolean"]>;
  sender_email?: InputMaybe<Scalars["String"]>;
  show_main_category?: InputMaybe<Scalars["Boolean"]>;
  show_talk_to_us?: InputMaybe<Scalars["Boolean"]>;
  simphony_pos_url?: InputMaybe<Scalars["String"]>;
  spa_id?: InputMaybe<Scalars["String"]>;
  star_rating?: InputMaybe<Scalars["Boolean"]>;
  tile_color?: InputMaybe<Scalars["String"]>;
  title_color?: InputMaybe<Scalars["String"]>;
  transperancy?: InputMaybe<Scalars["Float"]>;
  whatsapp_icon?: InputMaybe<Scalars["Boolean"]>;
};

export type Query = {
  __typename?: "Query";
  getActiveItineraries?: Maybe<Array<Maybe<Itinerary>>>;
  getAdminPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getAllDineInSections?: Maybe<Array<Maybe<DineInSection>>>;
  getAllEMenu?: Maybe<Array<Maybe<EMenu>>>;
  getAllergens?: Maybe<Array<Maybe<Allergens>>>;
  getClosedItineraries?: Maybe<Array<Maybe<Itinerary>>>;
  getDepartments?: Maybe<Array<Maybe<Department>>>;
  getDineInCategories?: Maybe<Array<Maybe<DineInCategories>>>;
  getDineInSections?: Maybe<Array<Maybe<DineInSection>>>;
  getEMenu?: Maybe<EMenu>;
  getEMenuGuestOrders?: Maybe<Array<Maybe<Order>>>;
  getEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getFeedbackTypeList?: Maybe<Array<Maybe<Feedback>>>;
  getFlexiPass?: Maybe<Scalars["String"]>;
  getGuest?: Maybe<Response>;
  getGuestDineInCategories?: Maybe<Array<Maybe<DineInCategories>>>;
  getGuestEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getGuestList?: Maybe<Array<Maybe<Guest>>>;
  getGuestOrders?: Maybe<Array<Maybe<Order>>>;
  getGuestPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getHotelService?: Maybe<HotelService>;
  getHotlyncServices?: Maybe<Array<Maybe<HotlyncServices>>>;
  getInvoice?: Maybe<Invoice>;
  getItinerary?: Maybe<Array<Maybe<Itinerary>>>;
  getItineraryTypes?: Maybe<Array<Maybe<ItineraryTypes>>>;
  getMeta?: Maybe<MetaData>;
  getOrders?: Maybe<Array<Maybe<Order>>>;
  getOrdersByStatus?: Maybe<Array<Maybe<Order>>>;
  getOtp?: Maybe<Response>;
  getPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getProperty?: Maybe<Property>;
  getPropertyByDomain?: Maybe<Property>;
  getPropertyRoles?: Maybe<Array<Maybe<Role>>>;
  getPropertyTreatments?: Maybe<Array<Maybe<Treatment>>>;
  getPropertyUsers?: Maybe<Array<Maybe<User>>>;
  getQuickServices?: Maybe<QuickServices>;
  getServices?: Maybe<ServiceByDeptmt>;
  getSubProperties?: Maybe<Array<Maybe<SubProperty>>>;
  getTaskHistory?: Maybe<Array<Maybe<TaskHistory>>>;
  getUploadSignedURL?: Maybe<SignedUrl>;
  getUser?: Maybe<User>;
  getUserByEmail?: Maybe<User>;
  simphonyDiscounts?: Maybe<StatusResponse>;
  simphonyServiceCharges?: Maybe<StatusResponse>;
  simphonySignIn?: Maybe<Scalars["String"]>;
  verifyOtp?: Maybe<OtpResponse>;
};

export type QueryGetActiveItinerariesArgs = {
  endDate: Scalars["String"];
  propertyID: Scalars["String"];
  startDate: Scalars["String"];
};

export type QueryGetAdminPromotionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetAllDineInSectionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetAllEMenuArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetAllergensArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetClosedItinerariesArgs = {
  propertyID: Scalars["String"];
  startDate: Scalars["String"];
};

export type QueryGetDepartmentsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetDineInCategoriesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetDineInSectionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetEMenuArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetEMenuGuestOrdersArgs = {
  externalGuestID: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetEdiblesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetFeedbackTypeListArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetFlexiPassArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestArgs = {
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetGuestDineInCategoriesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestEdiblesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestListArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestOrdersArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
};

export type QueryGetGuestPromotionsArgs = {
  currDate: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetHotelServiceArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetHotlyncServicesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetInvoiceArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetItineraryArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetItineraryTypesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetMetaArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetOrdersArgs = {
  endDate?: InputMaybe<Scalars["String"]>;
  propertyID: Scalars["String"];
  startDate?: InputMaybe<Scalars["String"]>;
};

export type QueryGetOrdersByStatusArgs = {
  orderStatus: Status;
  propertyID: Scalars["String"];
};

export type QueryGetOtpArgs = {
  id: Scalars["String"];
  method: AuthMethod;
  propertyID: Scalars["String"];
};

export type QueryGetPromotionsArgs = {
  currDate: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetPropertyArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetPropertyByDomainArgs = {
  domain: Scalars["String"];
};

export type QueryGetPropertyRolesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetPropertyTreatmentsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetPropertyUsersArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetQuickServicesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetServicesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetSubPropertiesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetTaskHistoryArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetUploadSignedUrlArgs = {
  extension: Scalars["String"];
  fileType: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetUserArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QuerySimphonyDiscountsArgs = {
  propertyID: Scalars["String"];
};

export type QuerySimphonyServiceChargesArgs = {
  propertyID: Scalars["String"];
};

export type QuerySimphonySignInArgs = {
  propertyID: Scalars["String"];
};

export type QueryVerifyOtpArgs = {
  otp: Scalars["Int"];
  propertyID: Scalars["String"];
};

export type QuickService = {
  __typename?: "QuickService";
  department: Scalars["String"];
  id: Scalars["String"];
  qty_by_guest: Scalars["Int"];
  task: Scalars["String"];
};

export type QuickServices = {
  __typename?: "QuickServices";
  code: Scalars["Int"];
  quicktasklist?: Maybe<Array<Maybe<QuickService>>>;
};

export type Response = {
  __typename?: "Response";
  code: Scalars["String"];
  guest_arrival?: Maybe<Scalars["String"]>;
  guest_departure?: Maybe<Scalars["String"]>;
  guest_id?: Maybe<Scalars["Int"]>;
  guest_name?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  property_id?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
};

export type Role = {
  __typename?: "Role";
  id: Scalars["ID"];
  name: Scalars["String"];
  permission?: Maybe<PermissionTypes>;
  property_id: Scalars["String"];
};

export type RoleInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
  permission?: InputMaybe<PermissionTypesInput>;
  property_id: Scalars["String"];
};

export type Room = {
  __typename?: "Room";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  property_id: Scalars["String"];
  room_type: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
};

export type RoomInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  property_id: Scalars["String"];
  room_type: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type Selection = {
  __typename?: "Selection";
  id: Scalars["String"];
  limit?: Maybe<Scalars["Int"]>;
  min_limit?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  selection_items: Array<Maybe<SelectionItem>>;
};

export type SelectionInput = {
  id: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  min_limit?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  selection_items: Array<InputMaybe<SelectionItemInput>>;
};

export type SelectionItem = {
  __typename?: "SelectionItem";
  id: Scalars["String"];
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type SelectionItemInput = {
  id: Scalars["String"];
  name: Scalars["String"];
  price?: InputMaybe<Scalars["Float"]>;
};

export type Service = {
  __typename?: "Service";
  id: Scalars["Int"];
  task: Scalars["String"];
};

export type ServiceByDeptmt = {
  __typename?: "ServiceByDeptmt";
  code: Scalars["Int"];
  tasklist?: Maybe<Scalars["AWSJSON"]>;
};

export type ServiceInput = {
  menu_items?: InputMaybe<Array<InputMaybe<MenuItems>>>;
  notes?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  quantity: Scalars["Int"];
  room_id: Scalars["String"];
  schedule_task?: InputMaybe<Scalars["String"]>;
  task_id: Scalars["Int"];
};

export type SignedUrl = {
  __typename?: "SignedURL";
  presigned_upload_url?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum Status {
  Cancelled = "CANCELLED",
  Delivered = "DELIVERED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export type StatusResponse = {
  __typename?: "StatusResponse";
  data?: Maybe<Scalars["AWSJSON"]>;
  message?: Maybe<Scalars["String"]>;
  status: Scalars["Int"];
};

export type SubProperty = {
  __typename?: "SubProperty";
  id: Scalars["ID"];
  img?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sub_services?: Maybe<Array<Maybe<SubService>>>;
};

export type SubPropertyInput = {
  id?: InputMaybe<Scalars["ID"]>;
  img?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sub_services?: InputMaybe<Array<InputMaybe<SubServiceInput>>>;
};

export type SubService = {
  __typename?: "SubService";
  booking_form?: Maybe<Scalars["Boolean"]>;
  booking_url?: Maybe<Scalars["String"]>;
  desc?: Maybe<Scalars["String"]>;
  img?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  noti_msg?: Maybe<Scalars["String"]>;
  service_id?: Maybe<Scalars["Int"]>;
  spa_integration?: Maybe<Scalars["Boolean"]>;
};

export type SubServiceInput = {
  booking_form?: InputMaybe<Scalars["Boolean"]>;
  booking_url?: InputMaybe<Scalars["String"]>;
  desc?: InputMaybe<Scalars["String"]>;
  img?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  noti_msg?: InputMaybe<Scalars["String"]>;
  service_id?: InputMaybe<Scalars["Int"]>;
  spa_integration?: InputMaybe<Scalars["Boolean"]>;
};

export type TaskHistory = {
  __typename?: "TaskHistory";
  created_time?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  task_list?: Maybe<Scalars["Int"]>;
  task_name?: Maybe<Scalars["String"]>;
};

export type Treatment = {
  __typename?: "Treatment";
  category?: Maybe<TreatmentCategory>;
  default_durations?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id: Scalars["ID"];
  image_url?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
  property_id: Scalars["String"];
};

export enum TreatmentCategory {
  Massage = "MASSAGE",
  Other = "OTHER",
}

export enum Types {
  Alcohol = "ALCOHOL",
  Breakfast = "BREAKFAST",
  Desserts = "DESSERTS",
  Drinks = "DRINKS",
  HotDrinks = "HOT_DRINKS",
  Mains = "MAINS",
  Salads = "SALADS",
  Soups = "SOUPS",
  Starters = "STARTERS",
}

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["ID"];
  is_authorized?: Maybe<Scalars["Boolean"]>;
  is_disabled?: Maybe<Scalars["Boolean"]>;
  last_name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  profile_url?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  reports_to?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  shift_end?: Maybe<Scalars["String"]>;
  shift_start?: Maybe<Scalars["String"]>;
  working_days?: Maybe<Array<Maybe<Days>>>;
};

export type UserInput = {
  email: Scalars["String"];
  first_name: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  is_authorized?: InputMaybe<Scalars["Boolean"]>;
  is_disabled?: InputMaybe<Scalars["Boolean"]>;
  last_name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  role?: InputMaybe<Scalars["String"]>;
  shift_end?: InputMaybe<Scalars["String"]>;
  shift_start?: InputMaybe<Scalars["String"]>;
  working_days?: InputMaybe<Array<InputMaybe<Days>>>;
};

export type GetAllergensQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetAllergensQuery = {
  __typename?: "Query";
  getAllergens?: Array<{
    __typename?: "Allergens";
    name: string;
    id: string;
    icon?: string | null;
    property_id: string;
    uploaded_icon?: string | null;
  } | null> | null;
};

export type AddAllergensMutationVariables = Exact<{
  allergensInput: AllergensInput;
}>;

export type AddAllergensMutation = {
  __typename?: "Mutation";
  upsertAllergens?: { __typename?: "Allergens"; id: string } | null;
};

export type DeleteAllergensMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteAllergensMutation = {
  __typename?: "Mutation";
  deleteAllergens?: string | null;
};

export type GetDineInCategoriesQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetDineInCategoriesQuery = {
  __typename?: "Query";
  getDineInCategories?: Array<{
    __typename?: "DineInCategories";
    name: string;
    id: string;
    icon: string;
    property_id: string;
    desc?: string | null;
    sequence?: number | null;
    uploaded_icon?: string | null;
    is_Activated?: boolean | null;
  } | null> | null;
};

export type AddDineInCategoriesMutationVariables = Exact<{
  dineInCategoriesInput: DineInCategoriesInput;
}>;

export type AddDineInCategoriesMutation = {
  __typename?: "Mutation";
  upsertDineInCategory?: { __typename?: "DineInCategories"; id: string } | null;
};

export type DeleteDineInCategoriesMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteDineInCategoriesMutation = {
  __typename?: "Mutation";
  deleteDineInCategory?: string | null;
};

export type GetDineInSectionsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetDineInSectionsQuery = {
  __typename?: "Query";
  getAllDineInSections?: Array<{
    __typename?: "DineInSection";
    name: string;
    id: string;
    icon: string;
    property_id: string;
    desc?: string | null;
    start_time?: string | null;
    end_time?: string | null;
    sequence?: number | null;
    default_category?: string | null;
    time_based?: boolean | null;
    message?: string | null;
    uploaded_icon?: string | null;
    is_Activated?: boolean | null;
  } | null> | null;
};

export type AddDineInSectionsMutationVariables = Exact<{
  dineInSectionInput: DineInSectionInput;
}>;

export type AddDineInSectionsMutation = {
  __typename?: "Mutation";
  upsertDineInSection?: { __typename?: "DineInSection"; id: string } | null;
};

export type DeleteDineInSectionsMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteDineInSectionsMutation = {
  __typename?: "Mutation";
  deleteDineInSection?: string | null;
};

export type GetEdibleQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetEdibleQuery = {
  __typename?: "Query";
  getEdibles?: Array<{
    __typename?: "DineInItem";
    id: string;
    property_id: string;
    name: string;
    type: string;
    img: string;
    desc: string;
    price: number;
    isVegan?: Diet_Type | null;
    availabletime?: string | null;
    allergens?: Array<string | null> | null;
    is_Activated?: boolean | null;
    section?: Array<string | null> | null;
    is_recommended?: boolean | null;
    spice_level?: string | null;
    sequence?: number | null;
    extraOptions?: Array<{
      __typename?: "ExtraOptions";
      name: string;
      price?: number | null;
    } | null> | null;
    selections?: Array<{
      __typename?: "Selection";
      id: string;
      name: string;
      min_limit?: number | null;
      limit?: number | null;
      selection_items: Array<{
        __typename?: "SelectionItem";
        id: string;
        name: string;
        price?: number | null;
      } | null>;
    } | null> | null;
  } | null> | null;
};

export type AddEdibleMutationVariables = Exact<{
  dineInItemInput: DineInItemInput;
}>;

export type AddEdibleMutation = {
  __typename?: "Mutation";
  upsertEdible?: { __typename?: "DineInItem"; id: string } | null;
};

export type DeleteEdibleMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteEdibleMutation = {
  __typename?: "Mutation";
  deleteEdible?: string | null;
};

export type UpdateEdibleStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type UpdateEdibleStatusMutation = {
  __typename?: "Mutation";
  updateEdibleStatus?: {
    __typename?: "DineInItem";
    id: string;
    property_id: string;
    name: string;
    type: string;
    img: string;
    desc: string;
    price: number;
    isVegan?: Diet_Type | null;
    availabletime?: string | null;
    allergens?: Array<string | null> | null;
    is_Activated?: boolean | null;
    section?: Array<string | null> | null;
    extraOptions?: Array<{
      __typename?: "ExtraOptions";
      name: string;
      price?: number | null;
    } | null> | null;
  } | null;
};

export type GetAllEMenuQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetAllEMenuQuery = {
  __typename?: "Query";
  getAllEMenu?: Array<{
    __typename?: "EMenu";
    id: string;
    property_id: string;
    name: string;
    interactive?: boolean | null;
    department_id?: number | null;
    auth_method?: AuthMethod | null;
    email?: string | null;
  } | null> | null;
};

export type GetEMenuQueryVariables = Exact<{
  propertyID: Scalars["String"];
  id: Scalars["String"];
}>;

export type GetEMenuQuery = {
  __typename?: "Query";
  getEMenu?: {
    __typename?: "EMenu";
    id: string;
    property_id: string;
    name: string;
    items?: Array<{
      __typename?: "DineInItem";
      id: string;
      name: string;
      type: string;
      img: string;
      desc: string;
      price: number;
      isVegan?: Diet_Type | null;
      availabletime?: string | null;
      allergens?: Array<string | null> | null;
      section?: Array<string | null> | null;
      is_recommended?: boolean | null;
      spice_level?: string | null;
      sequence?: number | null;
      extraOptions?: Array<{
        __typename?: "ExtraOptions";
        name: string;
        price?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type AddEMenuMutationVariables = Exact<{
  eMenu: EMenuInput;
}>;

export type AddEMenuMutation = {
  __typename?: "Mutation";
  upsertEMenu?: { __typename?: "EMenu"; id: string } | null;
};

export type DeleteEMenuMutationVariables = Exact<{
  propertyID: Scalars["String"];
  id: Scalars["String"];
}>;

export type DeleteEMenuMutation = {
  __typename?: "Mutation";
  deleteEMenu?: string | null;
};

export type GetHotelDineInQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetHotelDineInQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    id: string;
    dining?: Array<{
      __typename?: "Dining";
      desc: string;
      id: string;
      img: string;
      name: string;
      property_id: string;
      service_id?: number | null;
    } | null> | null;
  } | null;
};

export type AddHotelDineInMutationVariables = Exact<{
  diningInput: DiningInput;
}>;

export type AddHotelDineInMutation = {
  __typename?: "Mutation";
  upsertDining?: string | null;
};

export type DeleteDiningMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteDiningMutation = {
  __typename?: "Mutation";
  deleteDining?: string | null;
};

export type GetHotelInformationQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetHotelInformationQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    id: string;
    hotel_name: string;
    property_id: string;
    feedback_desc?: string | null;
    promotion_desc?: string | null;
    dining_image?: string | null;
    hotel_services_image?: string | null;
    rooms_image?: string | null;
    directory_description?: string | null;
    order_service_id?: number | null;
    has_order_service_id?: boolean | null;
    dir_con: {
      __typename?: "DirectionsAndContact";
      address: string;
      email: string;
      img: string;
      phone: string;
    };
    about: { __typename?: "About"; desc: string; img: string };
    artNdesign?: { __typename?: "About"; desc: string; img: string } | null;
    neighbourhood?: { __typename?: "About"; desc: string; img: string } | null;
    events?: { __typename?: "About"; desc: string; img: string } | null;
    safetyNsecurity?: {
      __typename?: "About";
      desc: string;
      img: string;
    } | null;
    is_Activated?: {
      __typename?: "Is_Activated";
      about?: boolean | null;
      dining?: boolean | null;
      directory?: boolean | null;
      hotel_rooms?: boolean | null;
      hotel_services?: boolean | null;
      artNdesign?: boolean | null;
      neighbourhood?: boolean | null;
      events?: boolean | null;
      safetyNsecurity?: boolean | null;
    } | null;
  } | null;
};

export type UpdatePropertyMutationVariables = Exact<{
  propertyInput: PropertyInput;
}>;

export type UpdatePropertyMutation = {
  __typename?: "Mutation";
  upsertProperty?: { __typename?: "Property"; id: string } | null;
};

export type UpdateMetaDataMutationVariables = Exact<{
  metaInput: MetaDataInput;
}>;

export type UpdateMetaDataMutation = {
  __typename?: "Mutation";
  upsertMetaData?: string | null;
};

export type UpdateApiKeyMutationVariables = Exact<{
  apiKey: Scalars["String"];
  propertyID: Scalars["String"];
}>;

export type UpdateApiKeyMutation = {
  __typename?: "Mutation";
  upsertApiKey?: string | null;
};

export type GetHotelServicesQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetHotelServicesQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    id: string;
    hotel_services?: Array<{
      __typename?: "HotelService";
      desc?: string | null;
      id: string;
      img?: string | null;
      name: string;
      property_id: string;
      icon?: string | null;
      uploaded_icon?: string | null;
      sequence?: number | null;
    }> | null;
  } | null;
};

export type GetHotelServiceQueryVariables = Exact<{
  propertyID: Scalars["String"];
  id: Scalars["String"];
}>;

export type GetHotelServiceQuery = {
  __typename?: "Query";
  getHotelService?: {
    __typename?: "HotelService";
    id: string;
    img?: string | null;
    name: string;
    desc?: string | null;
    property_id: string;
    sub_properties?: Array<string> | null;
    icon?: string | null;
    uploaded_icon?: string | null;
    sub_services?: Array<{
      __typename?: "SubService";
      name: string;
      img?: string | null;
      desc?: string | null;
      booking_url?: string | null;
      service_id?: number | null;
      booking_form?: boolean | null;
      spa_integration?: boolean | null;
      noti_msg?: string | null;
    } | null> | null;
  } | null;
};

export type AddServicesMutationVariables = Exact<{
  hotelServicesInput: HotelServiceInput;
}>;

export type AddServicesMutation = {
  __typename?: "Mutation";
  upsertHotelService?: string | null;
};

export type DeleteHotelServiceMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteHotelServiceMutation = {
  __typename?: "Mutation";
  deleteHotelService?: string | null;
};

export type GetActiveItinerariesQueryVariables = Exact<{
  propertyID: Scalars["String"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
}>;

export type GetActiveItinerariesQuery = {
  __typename?: "Query";
  getActiveItineraries?: Array<{
    __typename?: "Itinerary";
    id: string;
    description: string;
    duration: string;
    end_date: string;
    is_for_all?: boolean | null;
    is_repeated?: boolean | null;
    name?: string | null;
    property_id: string;
    start_date: string;
    time: string;
    type?: string | null;
    venue?: string | null;
    guest_list?: Array<{
      __typename?: "Guest";
      room?: string | null;
      guest_id?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetClosedItinerariesQueryVariables = Exact<{
  propertyID: Scalars["String"];
  startDate: Scalars["String"];
}>;

export type GetClosedItinerariesQuery = {
  __typename?: "Query";
  getClosedItineraries?: Array<{
    __typename?: "Itinerary";
    id: string;
    description: string;
    duration: string;
    end_date: string;
    is_for_all?: boolean | null;
    is_repeated?: boolean | null;
    name?: string | null;
    property_id: string;
    start_date: string;
    time: string;
    type?: string | null;
    venue?: string | null;
    guest_list?: Array<{
      __typename?: "Guest";
      room?: string | null;
      guest_id?: string | null;
    } | null> | null;
  } | null> | null;
};

export type AddItineraryMutationVariables = Exact<{
  itineraryInput: ItineraryInput;
}>;

export type AddItineraryMutation = {
  __typename?: "Mutation";
  upsertItinerary?: { __typename?: "Itinerary"; id: string } | null;
};

export type GetGuestListQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetGuestListQuery = {
  __typename?: "Query";
  getGuestList?: Array<{
    __typename?: "Guest";
    guest_id?: string | null;
    guest_name?: string | null;
    room?: string | null;
  } | null> | null;
};

export type GetItineraryTypesQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetItineraryTypesQuery = {
  __typename?: "Query";
  getItineraryTypes?: Array<{
    __typename?: "ItineraryTypes";
    id: string;
    name: string;
    property_id: string;
  } | null> | null;
};

export type DeleteItineraryMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteItineraryMutation = {
  __typename?: "Mutation";
  deleteItinerary?: string | null;
};

export type GetPropertyOrdersQueryVariables = Exact<{
  orderStatus: Status;
  propertyID: Scalars["String"];
}>;

export type GetPropertyOrdersQuery = {
  __typename?: "Query";
  getOrdersByStatus?: Array<{
    __typename?: "Order";
    guest_id: number;
    guest_name: string;
    id: string;
    order_id: string;
    property_id: string;
    room_id: number;
    status?: Status | null;
    total: number;
    created_at: string;
    updated_at?: string | null;
    comment?: string | null;
    cart_items?: Array<{
      __typename?: "Cart";
      dineInItem_id: string;
      quantity: number;
      extras?: Array<{
        __typename?: "ExtraOptions";
        name: string;
        price?: number | null;
      } | null> | null;
      selections?: Array<{
        __typename?: "Selection";
        id: string;
        limit?: number | null;
        name: string;
        selection_items: Array<{
          __typename?: "SelectionItem";
          name: string;
          id: string;
          price?: number | null;
        } | null>;
      } | null> | null;
    }> | null;
  } | null> | null;
};

export type GetOrdersQueryVariables = Exact<{
  propertyID: Scalars["String"];
  startDate?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["String"]>;
}>;

export type GetOrdersQuery = {
  __typename?: "Query";
  getOrders?: Array<{
    __typename?: "Order";
    guest_id: number;
    guest_name: string;
    id: string;
    order_id: string;
    property_id: string;
    room_id: number;
    status?: Status | null;
    total: number;
    created_at: string;
    updated_at?: string | null;
    comment?: string | null;
    cart_items?: Array<{
      __typename?: "Cart";
      dineInItem_id: string;
      dineInItem_name: string;
      dineInItem_price: number;
      quantity: number;
      extras?: Array<{
        __typename?: "ExtraOptions";
        name: string;
        price?: number | null;
      } | null> | null;
      selections?: Array<{
        __typename?: "Selection";
        id: string;
        limit?: number | null;
        name: string;
        selection_items: Array<{
          __typename?: "SelectionItem";
          name: string;
          id: string;
          price?: number | null;
        } | null>;
      } | null> | null;
    }> | null;
  } | null> | null;
};

export type GetPromotionQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetPromotionQuery = {
  __typename?: "Query";
  getAdminPromotions?: Array<{
    __typename?: "Promotion";
    banner_image: string;
    description: string;
    end_date: string;
    id: string;
    name: string;
    property_id: string;
    start_date: string;
    is_Activated?: boolean | null;
  } | null> | null;
};

export type AddPromotionMutationVariables = Exact<{
  promotionInput: PromotionInput;
}>;

export type AddPromotionMutation = {
  __typename?: "Mutation";
  upsertPromotion?: { __typename?: "Promotion"; id: string } | null;
};

export type DeletePromotionsMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeletePromotionsMutation = {
  __typename?: "Mutation";
  deletePromotion?: string | null;
};

export type UpdatePromotionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type UpdatePromotionStatusMutation = {
  __typename?: "Mutation";
  updatePromotionStatus?: {
    __typename?: "Promotion";
    banner_image: string;
    description: string;
    end_date: string;
    id: string;
    name: string;
    property_id: string;
    start_date: string;
    is_Activated?: boolean | null;
  } | null;
};

export type GetPropertyDetailsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetPropertyDetailsQuery = {
  __typename?: "Query";
  getProperty?: {
    __typename?: "Property";
    chat_api_url: string;
    domain?: string | null;
    hotlync_api_url?: string | null;
    id: string;
    img: string;
    name: string;
    primary_color: string;
    primary_light: string;
    primary_text_color: string;
    secondary_text_color: string;
    currency?: string | null;
    background_color?: string | null;
    font?: string | null;
    tile_color?: string | null;
    by_dining?: string | null;
    show_talk_to_us?: boolean | null;
    show_main_category?: boolean | null;
    has_simphony_pos?: boolean | null;
    simphony_pos_url?: string | null;
    direct_simphony_pos_check?: boolean | null;
    pos_loc_ref?: string | null;
    pos_rvc_ref?: string | null;
    hide_all_menu?: boolean | null;
    restaurant_email?: Array<string | null> | null;
    country?: string | null;
    palm_text?: string | null;
    palm_icon?: boolean | null;
    restaurant_phone?: string | null;
    spa_id?: string | null;
    whatsapp_icon?: boolean | null;
    title_color?: string | null;
    button_bg?: string | null;
    background_image?: string | null;
    transperancy?: number | null;
    logo_tile_bg?: string | null;
    logo_tile_transperancy?: number | null;
    star_rating?: boolean | null;
    sender_email?: string | null;
    send_to_simphony?: boolean | null;
    navbar_background_color?: string | null;
    navbar_active_background_color?: string | null;
    navbar_title_color?: string | null;
    navbar_active_title_color?: string | null;
    schedule_request?: boolean | null;
    list_service?: boolean | null;
    feedback_text?: string | null;
    chat_text?: string | null;
    layout?: Array<{
      __typename?: "Layout";
      cardName: string;
      displayName?: string | null;
      sequence: number;
      active?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetRoomsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetRoomsQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    id: string;
    hotel_rooms?: Array<{
      __typename?: "Room";
      desc: string;
      id: string;
      img: string;
      property_id: string;
      room_type: string;
      sequence?: number | null;
    }> | null;
  } | null;
};

export type AddRoomMutationVariables = Exact<{
  roomInput: RoomInput;
}>;

export type AddRoomMutation = {
  __typename?: "Mutation";
  upsertRoom?: { __typename?: "Room"; id: string } | null;
};

export type DeleteRoomMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteRoomMutation = {
  __typename?: "Mutation";
  deleteRoom?: string | null;
};

export type GetUploadSignedUrlQueryVariables = Exact<{
  fileType: Scalars["String"];
  extension: Scalars["String"];
  propertyID: Scalars["String"];
}>;

export type GetUploadSignedUrlQuery = {
  __typename?: "Query";
  getUploadSignedURL?: {
    __typename?: "SignedURL";
    url?: string | null;
    presigned_upload_url?: string | null;
  } | null;
};

export type GetHotlyncServicesQueryVariables = Exact<{
  property_id: Scalars["String"];
}>;

export type GetHotlyncServicesQuery = {
  __typename?: "Query";
  getHotlyncServices?: Array<{
    __typename?: "HotlyncServices";
    id: string;
    name: string;
    property_id: string;
    uploaded_icon?: string | null;
    icon?: string | null;
    sequence?: number | null;
  } | null> | null;
};

export type UpdateHotlyncServiceMutationVariables = Exact<{
  hotlyncServiceInput: HotlyncServicesInput;
}>;

export type UpdateHotlyncServiceMutation = {
  __typename?: "Mutation";
  upsertHotlyncServices?: { __typename?: "HotlyncServices"; id: string } | null;
};

export type PropertyServicesQueryVariables = Exact<{
  property_id: Scalars["String"];
}>;

export type PropertyServicesQuery = {
  __typename?: "Query";
  getServices?: {
    __typename?: "ServiceByDeptmt";
    code: number;
    tasklist?: any | null;
  } | null;
};

export type SyncSimphonyPosMutationVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type SyncSimphonyPosMutation = {
  __typename?: "Mutation";
  syncSimphonyPOSMenuItems?: {
    __typename?: "StatusResponse";
    status: number;
    message?: string | null;
    data?: any | null;
  } | null;
};

export type CreateSimphonyWithComboMealsMutationVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type CreateSimphonyWithComboMealsMutation = {
  __typename?: "Mutation";
  createSimphonyWithComboMeals?: {
    __typename?: "StatusResponse";
    status: number;
    message?: string | null;
    data?: any | null;
  } | null;
};

export type SimphonyDiscountsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type SimphonyDiscountsQuery = {
  __typename?: "Query";
  simphonyDiscounts?: {
    __typename?: "StatusResponse";
    status: number;
    message?: string | null;
    data?: any | null;
  } | null;
};

export type SimphonyServiceChargesQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type SimphonyServiceChargesQuery = {
  __typename?: "Query";
  simphonyServiceCharges?: {
    __typename?: "StatusResponse";
    status: number;
    message?: string | null;
    data?: any | null;
  } | null;
};

export type SimphonySignInQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type SimphonySignInQuery = {
  __typename?: "Query";
  simphonySignIn?: string | null;
};

export type GetDepartmentsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetDepartmentsQuery = {
  __typename?: "Query";
  getDepartments?: Array<{
    __typename?: "Department";
    id?: number | null;
    department?: string | null;
  } | null> | null;
};

export type GetSubPropertiesQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetSubPropertiesQuery = {
  __typename?: "Query";
  getSubProperties?: Array<{
    __typename?: "SubProperty";
    id: string;
    name: string;
    img?: string | null;
    property_id: string;
    sub_services?: Array<{
      __typename?: "SubService";
      name: string;
      img?: string | null;
      desc?: string | null;
      booking_url?: string | null;
      service_id?: number | null;
      booking_form?: boolean | null;
      spa_integration?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type AddSubPropertyMutationVariables = Exact<{
  subProperty: SubPropertyInput;
}>;

export type AddSubPropertyMutation = {
  __typename?: "Mutation";
  upsertSubProperty?: { __typename?: "SubProperty"; id: string } | null;
};

export type DeleteSubPropertyMutationVariables = Exact<{
  id: Scalars["String"];
  propertyID: Scalars["String"];
}>;

export type DeleteSubPropertyMutation = {
  __typename?: "Mutation";
  deleteSubProperty?: string | null;
};

export type GetPropertyUsersQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetPropertyUsersQuery = {
  __typename?: "Query";
  getPropertyUsers?: Array<{
    __typename?: "User";
    email: string;
    first_name: string;
    id: string;
    is_authorized?: boolean | null;
    is_disabled?: boolean | null;
    last_name: string;
    phone?: string | null;
    profile_url?: string | null;
    property_id: string;
    reports_to?: string | null;
    role?: string | null;
    shift_end?: string | null;
    shift_start?: string | null;
    working_days?: Array<Days | null> | null;
  } | null> | null;
};

export type DeleteUserMutationVariables = Exact<{
  propertyID: Scalars["String"];
  id: Scalars["ID"];
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser?: string | null;
};

export const GetAllergensDocument = gql`
  query getAllergens($propertyID: String!) {
    getAllergens(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      uploaded_icon
    }
  }
`;

/**
 * __useGetAllergensQuery__
 *
 * To run a query within a React component, call `useGetAllergensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllergensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllergensQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetAllergensQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >(GetAllergensDocument, options);
}
export function useGetAllergensLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >(GetAllergensDocument, options);
}
export type GetAllergensQueryHookResult = ReturnType<
  typeof useGetAllergensQuery
>;
export type GetAllergensLazyQueryHookResult = ReturnType<
  typeof useGetAllergensLazyQuery
>;
export type GetAllergensQueryResult = ApolloReactCommon.QueryResult<
  GetAllergensQuery,
  GetAllergensQueryVariables
>;
export const AddAllergensDocument = gql`
  mutation addAllergens($allergensInput: AllergensInput!) {
    upsertAllergens(allergensInput: $allergensInput) {
      id
    }
  }
`;
export type AddAllergensMutationFn = ApolloReactCommon.MutationFunction<
  AddAllergensMutation,
  AddAllergensMutationVariables
>;

/**
 * __useAddAllergensMutation__
 *
 * To run a mutation, you first call `useAddAllergensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAllergensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAllergensMutation, { data, loading, error }] = useAddAllergensMutation({
 *   variables: {
 *      allergensInput: // value for 'allergensInput'
 *   },
 * });
 */
export function useAddAllergensMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddAllergensMutation,
    AddAllergensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddAllergensMutation,
    AddAllergensMutationVariables
  >(AddAllergensDocument, options);
}
export type AddAllergensMutationHookResult = ReturnType<
  typeof useAddAllergensMutation
>;
export type AddAllergensMutationResult =
  ApolloReactCommon.MutationResult<AddAllergensMutation>;
export type AddAllergensMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAllergensMutation,
  AddAllergensMutationVariables
>;
export const DeleteAllergensDocument = gql`
  mutation deleteAllergens($id: ID!, $propertyID: String!) {
    deleteAllergens(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteAllergensMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAllergensMutation,
  DeleteAllergensMutationVariables
>;

/**
 * __useDeleteAllergensMutation__
 *
 * To run a mutation, you first call `useDeleteAllergensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllergensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllergensMutation, { data, loading, error }] = useDeleteAllergensMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteAllergensMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAllergensMutation,
    DeleteAllergensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteAllergensMutation,
    DeleteAllergensMutationVariables
  >(DeleteAllergensDocument, options);
}
export type DeleteAllergensMutationHookResult = ReturnType<
  typeof useDeleteAllergensMutation
>;
export type DeleteAllergensMutationResult =
  ApolloReactCommon.MutationResult<DeleteAllergensMutation>;
export type DeleteAllergensMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteAllergensMutation,
    DeleteAllergensMutationVariables
  >;
export const GetDineInCategoriesDocument = gql`
  query getDineInCategories($propertyID: String!) {
    getDineInCategories(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      desc
      sequence
      uploaded_icon
      is_Activated
    }
  }
`;

/**
 * __useGetDineInCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDineInCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDineInCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDineInCategoriesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetDineInCategoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >(GetDineInCategoriesDocument, options);
}
export function useGetDineInCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >(GetDineInCategoriesDocument, options);
}
export type GetDineInCategoriesQueryHookResult = ReturnType<
  typeof useGetDineInCategoriesQuery
>;
export type GetDineInCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetDineInCategoriesLazyQuery
>;
export type GetDineInCategoriesQueryResult = ApolloReactCommon.QueryResult<
  GetDineInCategoriesQuery,
  GetDineInCategoriesQueryVariables
>;
export const AddDineInCategoriesDocument = gql`
  mutation addDineInCategories($dineInCategoriesInput: DineInCategoriesInput!) {
    upsertDineInCategory(dineInCategoriesInput: $dineInCategoriesInput) {
      id
    }
  }
`;
export type AddDineInCategoriesMutationFn = ApolloReactCommon.MutationFunction<
  AddDineInCategoriesMutation,
  AddDineInCategoriesMutationVariables
>;

/**
 * __useAddDineInCategoriesMutation__
 *
 * To run a mutation, you first call `useAddDineInCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDineInCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDineInCategoriesMutation, { data, loading, error }] = useAddDineInCategoriesMutation({
 *   variables: {
 *      dineInCategoriesInput: // value for 'dineInCategoriesInput'
 *   },
 * });
 */
export function useAddDineInCategoriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddDineInCategoriesMutation,
    AddDineInCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddDineInCategoriesMutation,
    AddDineInCategoriesMutationVariables
  >(AddDineInCategoriesDocument, options);
}
export type AddDineInCategoriesMutationHookResult = ReturnType<
  typeof useAddDineInCategoriesMutation
>;
export type AddDineInCategoriesMutationResult =
  ApolloReactCommon.MutationResult<AddDineInCategoriesMutation>;
export type AddDineInCategoriesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddDineInCategoriesMutation,
    AddDineInCategoriesMutationVariables
  >;
export const DeleteDineInCategoriesDocument = gql`
  mutation deleteDineInCategories($id: ID!, $propertyID: String!) {
    deleteDineInCategory(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteDineInCategoriesMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteDineInCategoriesMutation,
    DeleteDineInCategoriesMutationVariables
  >;

/**
 * __useDeleteDineInCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteDineInCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDineInCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDineInCategoriesMutation, { data, loading, error }] = useDeleteDineInCategoriesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteDineInCategoriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDineInCategoriesMutation,
    DeleteDineInCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteDineInCategoriesMutation,
    DeleteDineInCategoriesMutationVariables
  >(DeleteDineInCategoriesDocument, options);
}
export type DeleteDineInCategoriesMutationHookResult = ReturnType<
  typeof useDeleteDineInCategoriesMutation
>;
export type DeleteDineInCategoriesMutationResult =
  ApolloReactCommon.MutationResult<DeleteDineInCategoriesMutation>;
export type DeleteDineInCategoriesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteDineInCategoriesMutation,
    DeleteDineInCategoriesMutationVariables
  >;
export const GetDineInSectionsDocument = gql`
  query getDineInSections($propertyID: String!) {
    getAllDineInSections(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      desc
      start_time
      end_time
      sequence
      default_category
      time_based
      message
      uploaded_icon
      is_Activated
    }
  }
`;

/**
 * __useGetDineInSectionsQuery__
 *
 * To run a query within a React component, call `useGetDineInSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDineInSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDineInSectionsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetDineInSectionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >(GetDineInSectionsDocument, options);
}
export function useGetDineInSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >(GetDineInSectionsDocument, options);
}
export type GetDineInSectionsQueryHookResult = ReturnType<
  typeof useGetDineInSectionsQuery
>;
export type GetDineInSectionsLazyQueryHookResult = ReturnType<
  typeof useGetDineInSectionsLazyQuery
>;
export type GetDineInSectionsQueryResult = ApolloReactCommon.QueryResult<
  GetDineInSectionsQuery,
  GetDineInSectionsQueryVariables
>;
export const AddDineInSectionsDocument = gql`
  mutation addDineInSections($dineInSectionInput: DineInSectionInput!) {
    upsertDineInSection(dineInSectionInput: $dineInSectionInput) {
      id
    }
  }
`;
export type AddDineInSectionsMutationFn = ApolloReactCommon.MutationFunction<
  AddDineInSectionsMutation,
  AddDineInSectionsMutationVariables
>;

/**
 * __useAddDineInSectionsMutation__
 *
 * To run a mutation, you first call `useAddDineInSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDineInSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDineInSectionsMutation, { data, loading, error }] = useAddDineInSectionsMutation({
 *   variables: {
 *      dineInSectionInput: // value for 'dineInSectionInput'
 *   },
 * });
 */
export function useAddDineInSectionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddDineInSectionsMutation,
    AddDineInSectionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddDineInSectionsMutation,
    AddDineInSectionsMutationVariables
  >(AddDineInSectionsDocument, options);
}
export type AddDineInSectionsMutationHookResult = ReturnType<
  typeof useAddDineInSectionsMutation
>;
export type AddDineInSectionsMutationResult =
  ApolloReactCommon.MutationResult<AddDineInSectionsMutation>;
export type AddDineInSectionsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddDineInSectionsMutation,
    AddDineInSectionsMutationVariables
  >;
export const DeleteDineInSectionsDocument = gql`
  mutation deleteDineInSections($id: ID!, $propertyID: String!) {
    deleteDineInSection(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteDineInSectionsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDineInSectionsMutation,
  DeleteDineInSectionsMutationVariables
>;

/**
 * __useDeleteDineInSectionsMutation__
 *
 * To run a mutation, you first call `useDeleteDineInSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDineInSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDineInSectionsMutation, { data, loading, error }] = useDeleteDineInSectionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteDineInSectionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDineInSectionsMutation,
    DeleteDineInSectionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteDineInSectionsMutation,
    DeleteDineInSectionsMutationVariables
  >(DeleteDineInSectionsDocument, options);
}
export type DeleteDineInSectionsMutationHookResult = ReturnType<
  typeof useDeleteDineInSectionsMutation
>;
export type DeleteDineInSectionsMutationResult =
  ApolloReactCommon.MutationResult<DeleteDineInSectionsMutation>;
export type DeleteDineInSectionsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteDineInSectionsMutation,
    DeleteDineInSectionsMutationVariables
  >;
export const GetEdibleDocument = gql`
  query getEdible($propertyID: String!) {
    getEdibles(propertyID: $propertyID) {
      id
      property_id
      name
      type
      img
      desc
      price
      isVegan
      availabletime
      allergens
      extraOptions {
        name
        price
      }
      is_Activated
      section
      is_recommended
      spice_level
      sequence
      selections {
        id
        name
        min_limit
        limit
        selection_items {
          id
          name
          price
        }
      }
    }
  }
`;

/**
 * __useGetEdibleQuery__
 *
 * To run a query within a React component, call `useGetEdibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEdibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEdibleQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetEdibleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEdibleQuery,
    GetEdibleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetEdibleQuery, GetEdibleQueryVariables>(
    GetEdibleDocument,
    options
  );
}
export function useGetEdibleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEdibleQuery,
    GetEdibleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetEdibleQuery, GetEdibleQueryVariables>(
    GetEdibleDocument,
    options
  );
}
export type GetEdibleQueryHookResult = ReturnType<typeof useGetEdibleQuery>;
export type GetEdibleLazyQueryHookResult = ReturnType<
  typeof useGetEdibleLazyQuery
>;
export type GetEdibleQueryResult = ApolloReactCommon.QueryResult<
  GetEdibleQuery,
  GetEdibleQueryVariables
>;
export const AddEdibleDocument = gql`
  mutation addEdible($dineInItemInput: DineInItemInput!) {
    upsertEdible(dineInItemInput: $dineInItemInput) {
      id
    }
  }
`;
export type AddEdibleMutationFn = ApolloReactCommon.MutationFunction<
  AddEdibleMutation,
  AddEdibleMutationVariables
>;

/**
 * __useAddEdibleMutation__
 *
 * To run a mutation, you first call `useAddEdibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEdibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEdibleMutation, { data, loading, error }] = useAddEdibleMutation({
 *   variables: {
 *      dineInItemInput: // value for 'dineInItemInput'
 *   },
 * });
 */
export function useAddEdibleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddEdibleMutation,
    AddEdibleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddEdibleMutation,
    AddEdibleMutationVariables
  >(AddEdibleDocument, options);
}
export type AddEdibleMutationHookResult = ReturnType<
  typeof useAddEdibleMutation
>;
export type AddEdibleMutationResult =
  ApolloReactCommon.MutationResult<AddEdibleMutation>;
export type AddEdibleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddEdibleMutation,
  AddEdibleMutationVariables
>;
export const DeleteEdibleDocument = gql`
  mutation deleteEdible($id: ID!, $propertyID: String!) {
    deleteEdible(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteEdibleMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEdibleMutation,
  DeleteEdibleMutationVariables
>;

/**
 * __useDeleteEdibleMutation__
 *
 * To run a mutation, you first call `useDeleteEdibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEdibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEdibleMutation, { data, loading, error }] = useDeleteEdibleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteEdibleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEdibleMutation,
    DeleteEdibleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEdibleMutation,
    DeleteEdibleMutationVariables
  >(DeleteEdibleDocument, options);
}
export type DeleteEdibleMutationHookResult = ReturnType<
  typeof useDeleteEdibleMutation
>;
export type DeleteEdibleMutationResult =
  ApolloReactCommon.MutationResult<DeleteEdibleMutation>;
export type DeleteEdibleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEdibleMutation,
  DeleteEdibleMutationVariables
>;
export const UpdateEdibleStatusDocument = gql`
  mutation updateEdibleStatus(
    $id: ID!
    $propertyID: String!
    $status: Boolean!
  ) {
    updateEdibleStatus(id: $id, propertyID: $propertyID, status: $status) {
      id
      property_id
      name
      type
      img
      desc
      price
      isVegan
      availabletime
      allergens
      extraOptions {
        name
        price
      }
      is_Activated
      section
    }
  }
`;
export type UpdateEdibleStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEdibleStatusMutation,
  UpdateEdibleStatusMutationVariables
>;

/**
 * __useUpdateEdibleStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEdibleStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEdibleStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEdibleStatusMutation, { data, loading, error }] = useUpdateEdibleStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateEdibleStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEdibleStatusMutation,
    UpdateEdibleStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEdibleStatusMutation,
    UpdateEdibleStatusMutationVariables
  >(UpdateEdibleStatusDocument, options);
}
export type UpdateEdibleStatusMutationHookResult = ReturnType<
  typeof useUpdateEdibleStatusMutation
>;
export type UpdateEdibleStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdateEdibleStatusMutation>;
export type UpdateEdibleStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEdibleStatusMutation,
    UpdateEdibleStatusMutationVariables
  >;
export const GetAllEMenuDocument = gql`
  query getAllEMenu($propertyID: String!) {
    getAllEMenu(propertyID: $propertyID) {
      id
      property_id
      name
      interactive
      department_id
      auth_method
      email
    }
  }
`;

/**
 * __useGetAllEMenuQuery__
 *
 * To run a query within a React component, call `useGetAllEMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEMenuQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetAllEMenuQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllEMenuQuery,
    GetAllEMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAllEMenuQuery, GetAllEMenuQueryVariables>(
    GetAllEMenuDocument,
    options
  );
}
export function useGetAllEMenuLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllEMenuQuery,
    GetAllEMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllEMenuQuery,
    GetAllEMenuQueryVariables
  >(GetAllEMenuDocument, options);
}
export type GetAllEMenuQueryHookResult = ReturnType<typeof useGetAllEMenuQuery>;
export type GetAllEMenuLazyQueryHookResult = ReturnType<
  typeof useGetAllEMenuLazyQuery
>;
export type GetAllEMenuQueryResult = ApolloReactCommon.QueryResult<
  GetAllEMenuQuery,
  GetAllEMenuQueryVariables
>;
export const GetEMenuDocument = gql`
  query getEMenu($propertyID: String!, $id: String!) {
    getEMenu(propertyID: $propertyID, id: $id) {
      id
      property_id
      name
      items {
        id
        name
        type
        img
        desc
        price
        isVegan
        availabletime
        allergens
        extraOptions {
          name
          price
        }
        section
        is_recommended
        spice_level
        sequence
      }
    }
  }
`;

/**
 * __useGetEMenuQuery__
 *
 * To run a query within a React component, call `useGetEMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEMenuQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEMenuQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEMenuQuery,
    GetEMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetEMenuQuery, GetEMenuQueryVariables>(
    GetEMenuDocument,
    options
  );
}
export function useGetEMenuLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEMenuQuery,
    GetEMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetEMenuQuery, GetEMenuQueryVariables>(
    GetEMenuDocument,
    options
  );
}
export type GetEMenuQueryHookResult = ReturnType<typeof useGetEMenuQuery>;
export type GetEMenuLazyQueryHookResult = ReturnType<
  typeof useGetEMenuLazyQuery
>;
export type GetEMenuQueryResult = ApolloReactCommon.QueryResult<
  GetEMenuQuery,
  GetEMenuQueryVariables
>;
export const AddEMenuDocument = gql`
  mutation addEMenu($eMenu: EMenuInput!) {
    upsertEMenu(eMenu: $eMenu) {
      id
    }
  }
`;
export type AddEMenuMutationFn = ApolloReactCommon.MutationFunction<
  AddEMenuMutation,
  AddEMenuMutationVariables
>;

/**
 * __useAddEMenuMutation__
 *
 * To run a mutation, you first call `useAddEMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEMenuMutation, { data, loading, error }] = useAddEMenuMutation({
 *   variables: {
 *      eMenu: // value for 'eMenu'
 *   },
 * });
 */
export function useAddEMenuMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddEMenuMutation,
    AddEMenuMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddEMenuMutation,
    AddEMenuMutationVariables
  >(AddEMenuDocument, options);
}
export type AddEMenuMutationHookResult = ReturnType<typeof useAddEMenuMutation>;
export type AddEMenuMutationResult =
  ApolloReactCommon.MutationResult<AddEMenuMutation>;
export type AddEMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddEMenuMutation,
  AddEMenuMutationVariables
>;
export const DeleteEMenuDocument = gql`
  mutation deleteEMenu($propertyID: String!, $id: String!) {
    deleteEMenu(propertyID: $propertyID, id: $id)
  }
`;
export type DeleteEMenuMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEMenuMutation,
  DeleteEMenuMutationVariables
>;

/**
 * __useDeleteEMenuMutation__
 *
 * To run a mutation, you first call `useDeleteEMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEMenuMutation, { data, loading, error }] = useDeleteEMenuMutation({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEMenuMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEMenuMutation,
    DeleteEMenuMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEMenuMutation,
    DeleteEMenuMutationVariables
  >(DeleteEMenuDocument, options);
}
export type DeleteEMenuMutationHookResult = ReturnType<
  typeof useDeleteEMenuMutation
>;
export type DeleteEMenuMutationResult =
  ApolloReactCommon.MutationResult<DeleteEMenuMutation>;
export type DeleteEMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEMenuMutation,
  DeleteEMenuMutationVariables
>;
export const GetHotelDineInDocument = gql`
  query getHotelDineIn($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      id
      dining {
        desc
        id
        img
        name
        property_id
        service_id
      }
    }
  }
`;

/**
 * __useGetHotelDineInQuery__
 *
 * To run a query within a React component, call `useGetHotelDineInQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelDineInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelDineInQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetHotelDineInQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelDineInQuery,
    GetHotelDineInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotelDineInQuery,
    GetHotelDineInQueryVariables
  >(GetHotelDineInDocument, options);
}
export function useGetHotelDineInLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelDineInQuery,
    GetHotelDineInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotelDineInQuery,
    GetHotelDineInQueryVariables
  >(GetHotelDineInDocument, options);
}
export type GetHotelDineInQueryHookResult = ReturnType<
  typeof useGetHotelDineInQuery
>;
export type GetHotelDineInLazyQueryHookResult = ReturnType<
  typeof useGetHotelDineInLazyQuery
>;
export type GetHotelDineInQueryResult = ApolloReactCommon.QueryResult<
  GetHotelDineInQuery,
  GetHotelDineInQueryVariables
>;
export const AddHotelDineInDocument = gql`
  mutation addHotelDineIn($diningInput: DiningInput!) {
    upsertDining(diningInput: $diningInput)
  }
`;
export type AddHotelDineInMutationFn = ApolloReactCommon.MutationFunction<
  AddHotelDineInMutation,
  AddHotelDineInMutationVariables
>;

/**
 * __useAddHotelDineInMutation__
 *
 * To run a mutation, you first call `useAddHotelDineInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHotelDineInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHotelDineInMutation, { data, loading, error }] = useAddHotelDineInMutation({
 *   variables: {
 *      diningInput: // value for 'diningInput'
 *   },
 * });
 */
export function useAddHotelDineInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddHotelDineInMutation,
    AddHotelDineInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddHotelDineInMutation,
    AddHotelDineInMutationVariables
  >(AddHotelDineInDocument, options);
}
export type AddHotelDineInMutationHookResult = ReturnType<
  typeof useAddHotelDineInMutation
>;
export type AddHotelDineInMutationResult =
  ApolloReactCommon.MutationResult<AddHotelDineInMutation>;
export type AddHotelDineInMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddHotelDineInMutation,
    AddHotelDineInMutationVariables
  >;
export const DeleteDiningDocument = gql`
  mutation deleteDining($id: ID!, $propertyID: String!) {
    deleteDining(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteDiningMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDiningMutation,
  DeleteDiningMutationVariables
>;

/**
 * __useDeleteDiningMutation__
 *
 * To run a mutation, you first call `useDeleteDiningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiningMutation, { data, loading, error }] = useDeleteDiningMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteDiningMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDiningMutation,
    DeleteDiningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteDiningMutation,
    DeleteDiningMutationVariables
  >(DeleteDiningDocument, options);
}
export type DeleteDiningMutationHookResult = ReturnType<
  typeof useDeleteDiningMutation
>;
export type DeleteDiningMutationResult =
  ApolloReactCommon.MutationResult<DeleteDiningMutation>;
export type DeleteDiningMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDiningMutation,
  DeleteDiningMutationVariables
>;
export const GetHotelInformationDocument = gql`
  query getHotelInformation($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      id
      hotel_name
      property_id
      dir_con {
        address
        email
        img
        phone
      }
      feedback_desc
      promotion_desc
      about {
        desc
        img
      }
      artNdesign {
        desc
        img
      }
      neighbourhood {
        desc
        img
      }
      events {
        desc
        img
      }
      safetyNsecurity {
        desc
        img
      }
      dining_image
      hotel_services_image
      rooms_image
      directory_description
      is_Activated {
        about
        dining
        directory
        hotel_rooms
        hotel_services
        artNdesign
        neighbourhood
        events
        safetyNsecurity
      }
      order_service_id
      has_order_service_id
    }
  }
`;

/**
 * __useGetHotelInformationQuery__
 *
 * To run a query within a React component, call `useGetHotelInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelInformationQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetHotelInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelInformationQuery,
    GetHotelInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotelInformationQuery,
    GetHotelInformationQueryVariables
  >(GetHotelInformationDocument, options);
}
export function useGetHotelInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelInformationQuery,
    GetHotelInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotelInformationQuery,
    GetHotelInformationQueryVariables
  >(GetHotelInformationDocument, options);
}
export type GetHotelInformationQueryHookResult = ReturnType<
  typeof useGetHotelInformationQuery
>;
export type GetHotelInformationLazyQueryHookResult = ReturnType<
  typeof useGetHotelInformationLazyQuery
>;
export type GetHotelInformationQueryResult = ApolloReactCommon.QueryResult<
  GetHotelInformationQuery,
  GetHotelInformationQueryVariables
>;
export const UpdatePropertyDocument = gql`
  mutation updateProperty($propertyInput: PropertyInput!) {
    upsertProperty(propertyInput: $propertyInput) {
      id
    }
  }
`;
export type UpdatePropertyMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      propertyInput: // value for 'propertyInput'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >(UpdatePropertyDocument, options);
}
export type UpdatePropertyMutationHookResult = ReturnType<
  typeof useUpdatePropertyMutation
>;
export type UpdatePropertyMutationResult =
  ApolloReactCommon.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >;
export const UpdateMetaDataDocument = gql`
  mutation updateMetaData($metaInput: MetaDataInput!) {
    upsertMetaData(metaInput: $metaInput)
  }
`;
export type UpdateMetaDataMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMetaDataMutation,
  UpdateMetaDataMutationVariables
>;

/**
 * __useUpdateMetaDataMutation__
 *
 * To run a mutation, you first call `useUpdateMetaDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetaDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetaDataMutation, { data, loading, error }] = useUpdateMetaDataMutation({
 *   variables: {
 *      metaInput: // value for 'metaInput'
 *   },
 * });
 */
export function useUpdateMetaDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMetaDataMutation,
    UpdateMetaDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateMetaDataMutation,
    UpdateMetaDataMutationVariables
  >(UpdateMetaDataDocument, options);
}
export type UpdateMetaDataMutationHookResult = ReturnType<
  typeof useUpdateMetaDataMutation
>;
export type UpdateMetaDataMutationResult =
  ApolloReactCommon.MutationResult<UpdateMetaDataMutation>;
export type UpdateMetaDataMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateMetaDataMutation,
    UpdateMetaDataMutationVariables
  >;
export const UpdateApiKeyDocument = gql`
  mutation updateApiKey($apiKey: String!, $propertyID: String!) {
    upsertApiKey(apiKey: $apiKey, propertyID: $propertyID)
  }
`;
export type UpdateApiKeyMutationFn = ApolloReactCommon.MutationFunction<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>;

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      apiKey: // value for 'apiKey'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateApiKeyMutation,
    UpdateApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateApiKeyMutation,
    UpdateApiKeyMutationVariables
  >(UpdateApiKeyDocument, options);
}
export type UpdateApiKeyMutationHookResult = ReturnType<
  typeof useUpdateApiKeyMutation
>;
export type UpdateApiKeyMutationResult =
  ApolloReactCommon.MutationResult<UpdateApiKeyMutation>;
export type UpdateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>;
export const GetHotelServicesDocument = gql`
  query getHotelServices($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      id
      hotel_services {
        desc
        id
        img
        name
        property_id
        icon
        uploaded_icon
        sequence
      }
    }
  }
`;

/**
 * __useGetHotelServicesQuery__
 *
 * To run a query within a React component, call `useGetHotelServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelServicesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetHotelServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelServicesQuery,
    GetHotelServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotelServicesQuery,
    GetHotelServicesQueryVariables
  >(GetHotelServicesDocument, options);
}
export function useGetHotelServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelServicesQuery,
    GetHotelServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotelServicesQuery,
    GetHotelServicesQueryVariables
  >(GetHotelServicesDocument, options);
}
export type GetHotelServicesQueryHookResult = ReturnType<
  typeof useGetHotelServicesQuery
>;
export type GetHotelServicesLazyQueryHookResult = ReturnType<
  typeof useGetHotelServicesLazyQuery
>;
export type GetHotelServicesQueryResult = ApolloReactCommon.QueryResult<
  GetHotelServicesQuery,
  GetHotelServicesQueryVariables
>;
export const GetHotelServiceDocument = gql`
  query getHotelService($propertyID: String!, $id: String!) {
    getHotelService(propertyID: $propertyID, id: $id) {
      id
      img
      name
      desc
      property_id
      sub_properties
      sub_services {
        name
        img
        desc
        booking_url
        service_id
        booking_form
        spa_integration
        noti_msg
      }
      icon
      uploaded_icon
    }
  }
`;

/**
 * __useGetHotelServiceQuery__
 *
 * To run a query within a React component, call `useGetHotelServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelServiceQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHotelServiceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelServiceQuery,
    GetHotelServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotelServiceQuery,
    GetHotelServiceQueryVariables
  >(GetHotelServiceDocument, options);
}
export function useGetHotelServiceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelServiceQuery,
    GetHotelServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotelServiceQuery,
    GetHotelServiceQueryVariables
  >(GetHotelServiceDocument, options);
}
export type GetHotelServiceQueryHookResult = ReturnType<
  typeof useGetHotelServiceQuery
>;
export type GetHotelServiceLazyQueryHookResult = ReturnType<
  typeof useGetHotelServiceLazyQuery
>;
export type GetHotelServiceQueryResult = ApolloReactCommon.QueryResult<
  GetHotelServiceQuery,
  GetHotelServiceQueryVariables
>;
export const AddServicesDocument = gql`
  mutation addServices($hotelServicesInput: HotelServiceInput!) {
    upsertHotelService(hotelServiceInput: $hotelServicesInput)
  }
`;
export type AddServicesMutationFn = ApolloReactCommon.MutationFunction<
  AddServicesMutation,
  AddServicesMutationVariables
>;

/**
 * __useAddServicesMutation__
 *
 * To run a mutation, you first call `useAddServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServicesMutation, { data, loading, error }] = useAddServicesMutation({
 *   variables: {
 *      hotelServicesInput: // value for 'hotelServicesInput'
 *   },
 * });
 */
export function useAddServicesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddServicesMutation,
    AddServicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddServicesMutation,
    AddServicesMutationVariables
  >(AddServicesDocument, options);
}
export type AddServicesMutationHookResult = ReturnType<
  typeof useAddServicesMutation
>;
export type AddServicesMutationResult =
  ApolloReactCommon.MutationResult<AddServicesMutation>;
export type AddServicesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddServicesMutation,
  AddServicesMutationVariables
>;
export const DeleteHotelServiceDocument = gql`
  mutation deleteHotelService($id: ID!, $propertyID: String!) {
    deleteHotelService(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteHotelServiceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteHotelServiceMutation,
  DeleteHotelServiceMutationVariables
>;

/**
 * __useDeleteHotelServiceMutation__
 *
 * To run a mutation, you first call `useDeleteHotelServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHotelServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHotelServiceMutation, { data, loading, error }] = useDeleteHotelServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteHotelServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteHotelServiceMutation,
    DeleteHotelServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteHotelServiceMutation,
    DeleteHotelServiceMutationVariables
  >(DeleteHotelServiceDocument, options);
}
export type DeleteHotelServiceMutationHookResult = ReturnType<
  typeof useDeleteHotelServiceMutation
>;
export type DeleteHotelServiceMutationResult =
  ApolloReactCommon.MutationResult<DeleteHotelServiceMutation>;
export type DeleteHotelServiceMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteHotelServiceMutation,
    DeleteHotelServiceMutationVariables
  >;
export const GetActiveItinerariesDocument = gql`
  query getActiveItineraries(
    $propertyID: String!
    $startDate: String!
    $endDate: String!
  ) {
    getActiveItineraries(
      propertyID: $propertyID
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      description
      duration
      end_date
      is_for_all
      is_repeated
      name
      property_id
      start_date
      time
      type
      venue
      guest_list {
        room
        guest_id
      }
    }
  }
`;

/**
 * __useGetActiveItinerariesQuery__
 *
 * To run a query within a React component, call `useGetActiveItinerariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveItinerariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveItinerariesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetActiveItinerariesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetActiveItinerariesQuery,
    GetActiveItinerariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetActiveItinerariesQuery,
    GetActiveItinerariesQueryVariables
  >(GetActiveItinerariesDocument, options);
}
export function useGetActiveItinerariesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetActiveItinerariesQuery,
    GetActiveItinerariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetActiveItinerariesQuery,
    GetActiveItinerariesQueryVariables
  >(GetActiveItinerariesDocument, options);
}
export type GetActiveItinerariesQueryHookResult = ReturnType<
  typeof useGetActiveItinerariesQuery
>;
export type GetActiveItinerariesLazyQueryHookResult = ReturnType<
  typeof useGetActiveItinerariesLazyQuery
>;
export type GetActiveItinerariesQueryResult = ApolloReactCommon.QueryResult<
  GetActiveItinerariesQuery,
  GetActiveItinerariesQueryVariables
>;
export const GetClosedItinerariesDocument = gql`
  query getClosedItineraries($propertyID: String!, $startDate: String!) {
    getClosedItineraries(propertyID: $propertyID, startDate: $startDate) {
      id
      description
      duration
      end_date
      is_for_all
      is_repeated
      name
      property_id
      start_date
      time
      type
      venue
      guest_list {
        room
        guest_id
      }
    }
  }
`;

/**
 * __useGetClosedItinerariesQuery__
 *
 * To run a query within a React component, call `useGetClosedItinerariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedItinerariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedItinerariesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetClosedItinerariesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetClosedItinerariesQuery,
    GetClosedItinerariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetClosedItinerariesQuery,
    GetClosedItinerariesQueryVariables
  >(GetClosedItinerariesDocument, options);
}
export function useGetClosedItinerariesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClosedItinerariesQuery,
    GetClosedItinerariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetClosedItinerariesQuery,
    GetClosedItinerariesQueryVariables
  >(GetClosedItinerariesDocument, options);
}
export type GetClosedItinerariesQueryHookResult = ReturnType<
  typeof useGetClosedItinerariesQuery
>;
export type GetClosedItinerariesLazyQueryHookResult = ReturnType<
  typeof useGetClosedItinerariesLazyQuery
>;
export type GetClosedItinerariesQueryResult = ApolloReactCommon.QueryResult<
  GetClosedItinerariesQuery,
  GetClosedItinerariesQueryVariables
>;
export const AddItineraryDocument = gql`
  mutation addItinerary($itineraryInput: ItineraryInput!) {
    upsertItinerary(itineraryInput: $itineraryInput) {
      id
    }
  }
`;
export type AddItineraryMutationFn = ApolloReactCommon.MutationFunction<
  AddItineraryMutation,
  AddItineraryMutationVariables
>;

/**
 * __useAddItineraryMutation__
 *
 * To run a mutation, you first call `useAddItineraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItineraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItineraryMutation, { data, loading, error }] = useAddItineraryMutation({
 *   variables: {
 *      itineraryInput: // value for 'itineraryInput'
 *   },
 * });
 */
export function useAddItineraryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddItineraryMutation,
    AddItineraryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddItineraryMutation,
    AddItineraryMutationVariables
  >(AddItineraryDocument, options);
}
export type AddItineraryMutationHookResult = ReturnType<
  typeof useAddItineraryMutation
>;
export type AddItineraryMutationResult =
  ApolloReactCommon.MutationResult<AddItineraryMutation>;
export type AddItineraryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddItineraryMutation,
  AddItineraryMutationVariables
>;
export const GetGuestListDocument = gql`
  query getGuestList($propertyID: String!) {
    getGuestList(propertyID: $propertyID) {
      guest_id
      guest_name
      room
    }
  }
`;

/**
 * __useGetGuestListQuery__
 *
 * To run a query within a React component, call `useGetGuestListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuestListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuestListQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetGuestListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGuestListQuery,
    GetGuestListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGuestListQuery,
    GetGuestListQueryVariables
  >(GetGuestListDocument, options);
}
export function useGetGuestListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGuestListQuery,
    GetGuestListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGuestListQuery,
    GetGuestListQueryVariables
  >(GetGuestListDocument, options);
}
export type GetGuestListQueryHookResult = ReturnType<
  typeof useGetGuestListQuery
>;
export type GetGuestListLazyQueryHookResult = ReturnType<
  typeof useGetGuestListLazyQuery
>;
export type GetGuestListQueryResult = ApolloReactCommon.QueryResult<
  GetGuestListQuery,
  GetGuestListQueryVariables
>;
export const GetItineraryTypesDocument = gql`
  query getItineraryTypes($propertyID: String!) {
    getItineraryTypes(propertyID: $propertyID) {
      id
      name
      property_id
    }
  }
`;

/**
 * __useGetItineraryTypesQuery__
 *
 * To run a query within a React component, call `useGetItineraryTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItineraryTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItineraryTypesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetItineraryTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetItineraryTypesQuery,
    GetItineraryTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetItineraryTypesQuery,
    GetItineraryTypesQueryVariables
  >(GetItineraryTypesDocument, options);
}
export function useGetItineraryTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetItineraryTypesQuery,
    GetItineraryTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetItineraryTypesQuery,
    GetItineraryTypesQueryVariables
  >(GetItineraryTypesDocument, options);
}
export type GetItineraryTypesQueryHookResult = ReturnType<
  typeof useGetItineraryTypesQuery
>;
export type GetItineraryTypesLazyQueryHookResult = ReturnType<
  typeof useGetItineraryTypesLazyQuery
>;
export type GetItineraryTypesQueryResult = ApolloReactCommon.QueryResult<
  GetItineraryTypesQuery,
  GetItineraryTypesQueryVariables
>;
export const DeleteItineraryDocument = gql`
  mutation deleteItinerary($id: ID!, $propertyID: String!) {
    deleteItinerary(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteItineraryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteItineraryMutation,
  DeleteItineraryMutationVariables
>;

/**
 * __useDeleteItineraryMutation__
 *
 * To run a mutation, you first call `useDeleteItineraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItineraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItineraryMutation, { data, loading, error }] = useDeleteItineraryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteItineraryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteItineraryMutation,
    DeleteItineraryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteItineraryMutation,
    DeleteItineraryMutationVariables
  >(DeleteItineraryDocument, options);
}
export type DeleteItineraryMutationHookResult = ReturnType<
  typeof useDeleteItineraryMutation
>;
export type DeleteItineraryMutationResult =
  ApolloReactCommon.MutationResult<DeleteItineraryMutation>;
export type DeleteItineraryMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteItineraryMutation,
    DeleteItineraryMutationVariables
  >;
export const GetPropertyOrdersDocument = gql`
  query getPropertyOrders($orderStatus: Status!, $propertyID: String!) {
    getOrdersByStatus(orderStatus: $orderStatus, propertyID: $propertyID) {
      guest_id
      cart_items {
        dineInItem_id
        extras {
          name
          price
        }
        quantity
        selections {
          id
          limit
          name
          selection_items {
            name
            id
            price
          }
        }
      }
      guest_name
      id
      order_id
      property_id
      room_id
      status
      total
      created_at
      updated_at
      comment
    }
  }
`;

/**
 * __useGetPropertyOrdersQuery__
 *
 * To run a query within a React component, call `useGetPropertyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyOrdersQuery({
 *   variables: {
 *      orderStatus: // value for 'orderStatus'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyOrdersQuery,
    GetPropertyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyOrdersQuery,
    GetPropertyOrdersQueryVariables
  >(GetPropertyOrdersDocument, options);
}
export function useGetPropertyOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyOrdersQuery,
    GetPropertyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyOrdersQuery,
    GetPropertyOrdersQueryVariables
  >(GetPropertyOrdersDocument, options);
}
export type GetPropertyOrdersQueryHookResult = ReturnType<
  typeof useGetPropertyOrdersQuery
>;
export type GetPropertyOrdersLazyQueryHookResult = ReturnType<
  typeof useGetPropertyOrdersLazyQuery
>;
export type GetPropertyOrdersQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyOrdersQuery,
  GetPropertyOrdersQueryVariables
>;
export const GetOrdersDocument = gql`
  query getOrders($propertyID: String!, $startDate: String, $endDate: String) {
    getOrders(
      propertyID: $propertyID
      startDate: $startDate
      endDate: $endDate
    ) {
      guest_id
      cart_items {
        dineInItem_id
        dineInItem_name
        dineInItem_price
        extras {
          name
          price
        }
        quantity
        selections {
          id
          limit
          name
          selection_items {
            name
            id
            price
          }
        }
      }
      guest_name
      id
      order_id
      property_id
      room_id
      status
      total
      created_at
      updated_at
      comment
    }
  }
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetOrdersQuery,
    GetOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options
  );
}
export function useGetOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrdersQuery,
    GetOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options
  );
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<
  typeof useGetOrdersLazyQuery
>;
export type GetOrdersQueryResult = ApolloReactCommon.QueryResult<
  GetOrdersQuery,
  GetOrdersQueryVariables
>;
export const GetPromotionDocument = gql`
  query getPromotion($propertyID: String!) {
    getAdminPromotions(propertyID: $propertyID) {
      banner_image
      description
      end_date
      id
      name
      property_id
      start_date
      is_Activated
    }
  }
`;

/**
 * __useGetPromotionQuery__
 *
 * To run a query within a React component, call `useGetPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPromotionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPromotionQuery,
    GetPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPromotionQuery,
    GetPromotionQueryVariables
  >(GetPromotionDocument, options);
}
export function useGetPromotionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPromotionQuery,
    GetPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPromotionQuery,
    GetPromotionQueryVariables
  >(GetPromotionDocument, options);
}
export type GetPromotionQueryHookResult = ReturnType<
  typeof useGetPromotionQuery
>;
export type GetPromotionLazyQueryHookResult = ReturnType<
  typeof useGetPromotionLazyQuery
>;
export type GetPromotionQueryResult = ApolloReactCommon.QueryResult<
  GetPromotionQuery,
  GetPromotionQueryVariables
>;
export const AddPromotionDocument = gql`
  mutation addPromotion($promotionInput: PromotionInput!) {
    upsertPromotion(promotionInput: $promotionInput) {
      id
    }
  }
`;
export type AddPromotionMutationFn = ApolloReactCommon.MutationFunction<
  AddPromotionMutation,
  AddPromotionMutationVariables
>;

/**
 * __useAddPromotionMutation__
 *
 * To run a mutation, you first call `useAddPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPromotionMutation, { data, loading, error }] = useAddPromotionMutation({
 *   variables: {
 *      promotionInput: // value for 'promotionInput'
 *   },
 * });
 */
export function useAddPromotionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPromotionMutation,
    AddPromotionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddPromotionMutation,
    AddPromotionMutationVariables
  >(AddPromotionDocument, options);
}
export type AddPromotionMutationHookResult = ReturnType<
  typeof useAddPromotionMutation
>;
export type AddPromotionMutationResult =
  ApolloReactCommon.MutationResult<AddPromotionMutation>;
export type AddPromotionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPromotionMutation,
  AddPromotionMutationVariables
>;
export const DeletePromotionsDocument = gql`
  mutation deletePromotions($id: ID!, $propertyID: String!) {
    deletePromotion(id: $id, propertyID: $propertyID)
  }
`;
export type DeletePromotionsMutationFn = ApolloReactCommon.MutationFunction<
  DeletePromotionsMutation,
  DeletePromotionsMutationVariables
>;

/**
 * __useDeletePromotionsMutation__
 *
 * To run a mutation, you first call `useDeletePromotionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromotionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromotionsMutation, { data, loading, error }] = useDeletePromotionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeletePromotionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePromotionsMutation,
    DeletePromotionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeletePromotionsMutation,
    DeletePromotionsMutationVariables
  >(DeletePromotionsDocument, options);
}
export type DeletePromotionsMutationHookResult = ReturnType<
  typeof useDeletePromotionsMutation
>;
export type DeletePromotionsMutationResult =
  ApolloReactCommon.MutationResult<DeletePromotionsMutation>;
export type DeletePromotionsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeletePromotionsMutation,
    DeletePromotionsMutationVariables
  >;
export const UpdatePromotionStatusDocument = gql`
  mutation updatePromotionStatus(
    $id: ID!
    $propertyID: String!
    $status: Boolean!
  ) {
    updatePromotionStatus(id: $id, propertyID: $propertyID, status: $status) {
      banner_image
      description
      end_date
      id
      name
      property_id
      start_date
      is_Activated
    }
  }
`;
export type UpdatePromotionStatusMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdatePromotionStatusMutation,
    UpdatePromotionStatusMutationVariables
  >;

/**
 * __useUpdatePromotionStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionStatusMutation, { data, loading, error }] = useUpdatePromotionStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePromotionStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePromotionStatusMutation,
    UpdatePromotionStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePromotionStatusMutation,
    UpdatePromotionStatusMutationVariables
  >(UpdatePromotionStatusDocument, options);
}
export type UpdatePromotionStatusMutationHookResult = ReturnType<
  typeof useUpdatePromotionStatusMutation
>;
export type UpdatePromotionStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdatePromotionStatusMutation>;
export type UpdatePromotionStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdatePromotionStatusMutation,
    UpdatePromotionStatusMutationVariables
  >;
export const GetPropertyDetailsDocument = gql`
  query getPropertyDetails($propertyID: String!) {
    getProperty(propertyID: $propertyID) {
      chat_api_url
      domain
      hotlync_api_url
      id
      img
      name
      primary_color
      primary_light
      primary_text_color
      secondary_text_color
      currency
      background_color
      font
      tile_color
      by_dining
      show_talk_to_us
      show_main_category
      has_simphony_pos
      simphony_pos_url
      direct_simphony_pos_check
      pos_loc_ref
      pos_rvc_ref
      hide_all_menu
      restaurant_email
      country
      layout {
        cardName
        displayName
        sequence
        active
      }
      palm_text
      palm_icon
      restaurant_phone
      spa_id
      whatsapp_icon
      title_color
      button_bg
      background_image
      transperancy
      logo_tile_bg
      logo_tile_transperancy
      star_rating
      sender_email
      send_to_simphony
      navbar_background_color
      navbar_active_background_color
      navbar_title_color
      navbar_active_title_color
      schedule_request
      list_service
      feedback_text
      chat_text
    }
  }
`;

/**
 * __useGetPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useGetPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyDetailsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >(GetPropertyDetailsDocument, options);
}
export function useGetPropertyDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >(GetPropertyDetailsDocument, options);
}
export type GetPropertyDetailsQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsQuery
>;
export type GetPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsLazyQuery
>;
export type GetPropertyDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyDetailsQuery,
  GetPropertyDetailsQueryVariables
>;
export const GetRoomsDocument = gql`
  query getRooms($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      id
      hotel_rooms {
        desc
        id
        img
        property_id
        room_type
        sequence
      }
    }
  }
`;

/**
 * __useGetRoomsQuery__
 *
 * To run a query within a React component, call `useGetRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetRoomsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRoomsQuery,
    GetRoomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetRoomsQuery, GetRoomsQueryVariables>(
    GetRoomsDocument,
    options
  );
}
export function useGetRoomsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRoomsQuery,
    GetRoomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetRoomsQuery, GetRoomsQueryVariables>(
    GetRoomsDocument,
    options
  );
}
export type GetRoomsQueryHookResult = ReturnType<typeof useGetRoomsQuery>;
export type GetRoomsLazyQueryHookResult = ReturnType<
  typeof useGetRoomsLazyQuery
>;
export type GetRoomsQueryResult = ApolloReactCommon.QueryResult<
  GetRoomsQuery,
  GetRoomsQueryVariables
>;
export const AddRoomDocument = gql`
  mutation addRoom($roomInput: RoomInput!) {
    upsertRoom(roomInput: $roomInput) {
      id
    }
  }
`;
export type AddRoomMutationFn = ApolloReactCommon.MutationFunction<
  AddRoomMutation,
  AddRoomMutationVariables
>;

/**
 * __useAddRoomMutation__
 *
 * To run a mutation, you first call `useAddRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoomMutation, { data, loading, error }] = useAddRoomMutation({
 *   variables: {
 *      roomInput: // value for 'roomInput'
 *   },
 * });
 */
export function useAddRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddRoomMutation,
    AddRoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddRoomMutation,
    AddRoomMutationVariables
  >(AddRoomDocument, options);
}
export type AddRoomMutationHookResult = ReturnType<typeof useAddRoomMutation>;
export type AddRoomMutationResult =
  ApolloReactCommon.MutationResult<AddRoomMutation>;
export type AddRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddRoomMutation,
  AddRoomMutationVariables
>;
export const DeleteRoomDocument = gql`
  mutation deleteRoom($id: ID!) {
    deleteRoom(id: $id)
  }
`;
export type DeleteRoomMutationFn = ApolloReactCommon.MutationFunction<
  DeleteRoomMutation,
  DeleteRoomMutationVariables
>;

/**
 * __useDeleteRoomMutation__
 *
 * To run a mutation, you first call `useDeleteRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomMutation, { data, loading, error }] = useDeleteRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteRoomMutation,
    DeleteRoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteRoomMutation,
    DeleteRoomMutationVariables
  >(DeleteRoomDocument, options);
}
export type DeleteRoomMutationHookResult = ReturnType<
  typeof useDeleteRoomMutation
>;
export type DeleteRoomMutationResult =
  ApolloReactCommon.MutationResult<DeleteRoomMutation>;
export type DeleteRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteRoomMutation,
  DeleteRoomMutationVariables
>;
export const GetUploadSignedUrlDocument = gql`
  query getUploadSignedURL(
    $fileType: String!
    $extension: String!
    $propertyID: String!
  ) {
    getUploadSignedURL(
      fileType: $fileType
      extension: $extension
      propertyID: $propertyID
    ) {
      url
      presigned_upload_url
    }
  }
`;

/**
 * __useGetUploadSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadSignedUrlQuery({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      extension: // value for 'extension'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetUploadSignedUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >(GetUploadSignedUrlDocument, options);
}
export function useGetUploadSignedUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >(GetUploadSignedUrlDocument, options);
}
export type GetUploadSignedUrlQueryHookResult = ReturnType<
  typeof useGetUploadSignedUrlQuery
>;
export type GetUploadSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetUploadSignedUrlLazyQuery
>;
export type GetUploadSignedUrlQueryResult = ApolloReactCommon.QueryResult<
  GetUploadSignedUrlQuery,
  GetUploadSignedUrlQueryVariables
>;
export const GetHotlyncServicesDocument = gql`
  query getHotlyncServices($property_id: String!) {
    getHotlyncServices(propertyID: $property_id) {
      id
      name
      property_id
      uploaded_icon
      icon
      sequence
    }
  }
`;

/**
 * __useGetHotlyncServicesQuery__
 *
 * To run a query within a React component, call `useGetHotlyncServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotlyncServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotlyncServicesQuery({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useGetHotlyncServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotlyncServicesQuery,
    GetHotlyncServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotlyncServicesQuery,
    GetHotlyncServicesQueryVariables
  >(GetHotlyncServicesDocument, options);
}
export function useGetHotlyncServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotlyncServicesQuery,
    GetHotlyncServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotlyncServicesQuery,
    GetHotlyncServicesQueryVariables
  >(GetHotlyncServicesDocument, options);
}
export type GetHotlyncServicesQueryHookResult = ReturnType<
  typeof useGetHotlyncServicesQuery
>;
export type GetHotlyncServicesLazyQueryHookResult = ReturnType<
  typeof useGetHotlyncServicesLazyQuery
>;
export type GetHotlyncServicesQueryResult = ApolloReactCommon.QueryResult<
  GetHotlyncServicesQuery,
  GetHotlyncServicesQueryVariables
>;
export const UpdateHotlyncServiceDocument = gql`
  mutation updateHotlyncService($hotlyncServiceInput: HotlyncServicesInput!) {
    upsertHotlyncServices(hotlyncServicesInput: $hotlyncServiceInput) {
      id
    }
  }
`;
export type UpdateHotlyncServiceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateHotlyncServiceMutation,
  UpdateHotlyncServiceMutationVariables
>;

/**
 * __useUpdateHotlyncServiceMutation__
 *
 * To run a mutation, you first call `useUpdateHotlyncServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHotlyncServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHotlyncServiceMutation, { data, loading, error }] = useUpdateHotlyncServiceMutation({
 *   variables: {
 *      hotlyncServiceInput: // value for 'hotlyncServiceInput'
 *   },
 * });
 */
export function useUpdateHotlyncServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHotlyncServiceMutation,
    UpdateHotlyncServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateHotlyncServiceMutation,
    UpdateHotlyncServiceMutationVariables
  >(UpdateHotlyncServiceDocument, options);
}
export type UpdateHotlyncServiceMutationHookResult = ReturnType<
  typeof useUpdateHotlyncServiceMutation
>;
export type UpdateHotlyncServiceMutationResult =
  ApolloReactCommon.MutationResult<UpdateHotlyncServiceMutation>;
export type UpdateHotlyncServiceMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateHotlyncServiceMutation,
    UpdateHotlyncServiceMutationVariables
  >;
export const PropertyServicesDocument = gql`
  query propertyServices($property_id: String!) {
    getServices(propertyID: $property_id) {
      code
      tasklist
    }
  }
`;

/**
 * __usePropertyServicesQuery__
 *
 * To run a query within a React component, call `usePropertyServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyServicesQuery({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function usePropertyServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >(PropertyServicesDocument, options);
}
export function usePropertyServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >(PropertyServicesDocument, options);
}
export type PropertyServicesQueryHookResult = ReturnType<
  typeof usePropertyServicesQuery
>;
export type PropertyServicesLazyQueryHookResult = ReturnType<
  typeof usePropertyServicesLazyQuery
>;
export type PropertyServicesQueryResult = ApolloReactCommon.QueryResult<
  PropertyServicesQuery,
  PropertyServicesQueryVariables
>;
export const SyncSimphonyPosDocument = gql`
  mutation syncSimphonyPOS($propertyID: String!) {
    syncSimphonyPOSMenuItems(propertyID: $propertyID) {
      status
      message
      data
    }
  }
`;
export type SyncSimphonyPosMutationFn = ApolloReactCommon.MutationFunction<
  SyncSimphonyPosMutation,
  SyncSimphonyPosMutationVariables
>;

/**
 * __useSyncSimphonyPosMutation__
 *
 * To run a mutation, you first call `useSyncSimphonyPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSimphonyPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSimphonyPosMutation, { data, loading, error }] = useSyncSimphonyPosMutation({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useSyncSimphonyPosMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SyncSimphonyPosMutation,
    SyncSimphonyPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SyncSimphonyPosMutation,
    SyncSimphonyPosMutationVariables
  >(SyncSimphonyPosDocument, options);
}
export type SyncSimphonyPosMutationHookResult = ReturnType<
  typeof useSyncSimphonyPosMutation
>;
export type SyncSimphonyPosMutationResult =
  ApolloReactCommon.MutationResult<SyncSimphonyPosMutation>;
export type SyncSimphonyPosMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SyncSimphonyPosMutation,
    SyncSimphonyPosMutationVariables
  >;
export const CreateSimphonyWithComboMealsDocument = gql`
  mutation createSimphonyWithComboMeals($propertyID: String!) {
    createSimphonyWithComboMeals(propertyID: $propertyID) {
      status
      message
      data
    }
  }
`;
export type CreateSimphonyWithComboMealsMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateSimphonyWithComboMealsMutation,
    CreateSimphonyWithComboMealsMutationVariables
  >;

/**
 * __useCreateSimphonyWithComboMealsMutation__
 *
 * To run a mutation, you first call `useCreateSimphonyWithComboMealsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimphonyWithComboMealsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimphonyWithComboMealsMutation, { data, loading, error }] = useCreateSimphonyWithComboMealsMutation({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useCreateSimphonyWithComboMealsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSimphonyWithComboMealsMutation,
    CreateSimphonyWithComboMealsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateSimphonyWithComboMealsMutation,
    CreateSimphonyWithComboMealsMutationVariables
  >(CreateSimphonyWithComboMealsDocument, options);
}
export type CreateSimphonyWithComboMealsMutationHookResult = ReturnType<
  typeof useCreateSimphonyWithComboMealsMutation
>;
export type CreateSimphonyWithComboMealsMutationResult =
  ApolloReactCommon.MutationResult<CreateSimphonyWithComboMealsMutation>;
export type CreateSimphonyWithComboMealsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateSimphonyWithComboMealsMutation,
    CreateSimphonyWithComboMealsMutationVariables
  >;
export const SimphonyDiscountsDocument = gql`
  query simphonyDiscounts($propertyID: String!) {
    simphonyDiscounts(propertyID: $propertyID) {
      status
      message
      data
    }
  }
`;

/**
 * __useSimphonyDiscountsQuery__
 *
 * To run a query within a React component, call `useSimphonyDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimphonyDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimphonyDiscountsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useSimphonyDiscountsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SimphonyDiscountsQuery,
    SimphonyDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SimphonyDiscountsQuery,
    SimphonyDiscountsQueryVariables
  >(SimphonyDiscountsDocument, options);
}
export function useSimphonyDiscountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SimphonyDiscountsQuery,
    SimphonyDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SimphonyDiscountsQuery,
    SimphonyDiscountsQueryVariables
  >(SimphonyDiscountsDocument, options);
}
export type SimphonyDiscountsQueryHookResult = ReturnType<
  typeof useSimphonyDiscountsQuery
>;
export type SimphonyDiscountsLazyQueryHookResult = ReturnType<
  typeof useSimphonyDiscountsLazyQuery
>;
export type SimphonyDiscountsQueryResult = ApolloReactCommon.QueryResult<
  SimphonyDiscountsQuery,
  SimphonyDiscountsQueryVariables
>;
export const SimphonyServiceChargesDocument = gql`
  query simphonyServiceCharges($propertyID: String!) {
    simphonyServiceCharges(propertyID: $propertyID) {
      status
      message
      data
    }
  }
`;

/**
 * __useSimphonyServiceChargesQuery__
 *
 * To run a query within a React component, call `useSimphonyServiceChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimphonyServiceChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimphonyServiceChargesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useSimphonyServiceChargesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SimphonyServiceChargesQuery,
    SimphonyServiceChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SimphonyServiceChargesQuery,
    SimphonyServiceChargesQueryVariables
  >(SimphonyServiceChargesDocument, options);
}
export function useSimphonyServiceChargesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SimphonyServiceChargesQuery,
    SimphonyServiceChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SimphonyServiceChargesQuery,
    SimphonyServiceChargesQueryVariables
  >(SimphonyServiceChargesDocument, options);
}
export type SimphonyServiceChargesQueryHookResult = ReturnType<
  typeof useSimphonyServiceChargesQuery
>;
export type SimphonyServiceChargesLazyQueryHookResult = ReturnType<
  typeof useSimphonyServiceChargesLazyQuery
>;
export type SimphonyServiceChargesQueryResult = ApolloReactCommon.QueryResult<
  SimphonyServiceChargesQuery,
  SimphonyServiceChargesQueryVariables
>;
export const SimphonySignInDocument = gql`
  query simphonySignIn($propertyID: String!) {
    simphonySignIn(propertyID: $propertyID)
  }
`;

/**
 * __useSimphonySignInQuery__
 *
 * To run a query within a React component, call `useSimphonySignInQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimphonySignInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimphonySignInQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useSimphonySignInQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SimphonySignInQuery,
    SimphonySignInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SimphonySignInQuery,
    SimphonySignInQueryVariables
  >(SimphonySignInDocument, options);
}
export function useSimphonySignInLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SimphonySignInQuery,
    SimphonySignInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SimphonySignInQuery,
    SimphonySignInQueryVariables
  >(SimphonySignInDocument, options);
}
export type SimphonySignInQueryHookResult = ReturnType<
  typeof useSimphonySignInQuery
>;
export type SimphonySignInLazyQueryHookResult = ReturnType<
  typeof useSimphonySignInLazyQuery
>;
export type SimphonySignInQueryResult = ApolloReactCommon.QueryResult<
  SimphonySignInQuery,
  SimphonySignInQueryVariables
>;
export const GetDepartmentsDocument = gql`
  query getDepartments($propertyID: String!) {
    getDepartments(propertyID: $propertyID) {
      id
      department
    }
  }
`;

/**
 * __useGetDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetDepartmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GetDepartmentsDocument, options);
}
export function useGetDepartmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GetDepartmentsDocument, options);
}
export type GetDepartmentsQueryHookResult = ReturnType<
  typeof useGetDepartmentsQuery
>;
export type GetDepartmentsLazyQueryHookResult = ReturnType<
  typeof useGetDepartmentsLazyQuery
>;
export type GetDepartmentsQueryResult = ApolloReactCommon.QueryResult<
  GetDepartmentsQuery,
  GetDepartmentsQueryVariables
>;
export const GetSubPropertiesDocument = gql`
  query getSubProperties($propertyID: String!) {
    getSubProperties(propertyID: $propertyID) {
      id
      name
      img
      property_id
      sub_services {
        name
        img
        desc
        booking_url
        service_id
        booking_form
        spa_integration
      }
    }
  }
`;

/**
 * __useGetSubPropertiesQuery__
 *
 * To run a query within a React component, call `useGetSubPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubPropertiesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetSubPropertiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSubPropertiesQuery,
    GetSubPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSubPropertiesQuery,
    GetSubPropertiesQueryVariables
  >(GetSubPropertiesDocument, options);
}
export function useGetSubPropertiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSubPropertiesQuery,
    GetSubPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSubPropertiesQuery,
    GetSubPropertiesQueryVariables
  >(GetSubPropertiesDocument, options);
}
export type GetSubPropertiesQueryHookResult = ReturnType<
  typeof useGetSubPropertiesQuery
>;
export type GetSubPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetSubPropertiesLazyQuery
>;
export type GetSubPropertiesQueryResult = ApolloReactCommon.QueryResult<
  GetSubPropertiesQuery,
  GetSubPropertiesQueryVariables
>;
export const AddSubPropertyDocument = gql`
  mutation addSubProperty($subProperty: SubPropertyInput!) {
    upsertSubProperty(subProperty: $subProperty) {
      id
    }
  }
`;
export type AddSubPropertyMutationFn = ApolloReactCommon.MutationFunction<
  AddSubPropertyMutation,
  AddSubPropertyMutationVariables
>;

/**
 * __useAddSubPropertyMutation__
 *
 * To run a mutation, you first call `useAddSubPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubPropertyMutation, { data, loading, error }] = useAddSubPropertyMutation({
 *   variables: {
 *      subProperty: // value for 'subProperty'
 *   },
 * });
 */
export function useAddSubPropertyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSubPropertyMutation,
    AddSubPropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddSubPropertyMutation,
    AddSubPropertyMutationVariables
  >(AddSubPropertyDocument, options);
}
export type AddSubPropertyMutationHookResult = ReturnType<
  typeof useAddSubPropertyMutation
>;
export type AddSubPropertyMutationResult =
  ApolloReactCommon.MutationResult<AddSubPropertyMutation>;
export type AddSubPropertyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddSubPropertyMutation,
    AddSubPropertyMutationVariables
  >;
export const DeleteSubPropertyDocument = gql`
  mutation deleteSubProperty($id: String!, $propertyID: String!) {
    deleteSubProperty(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteSubPropertyMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSubPropertyMutation,
  DeleteSubPropertyMutationVariables
>;

/**
 * __useDeleteSubPropertyMutation__
 *
 * To run a mutation, you first call `useDeleteSubPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubPropertyMutation, { data, loading, error }] = useDeleteSubPropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteSubPropertyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteSubPropertyMutation,
    DeleteSubPropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteSubPropertyMutation,
    DeleteSubPropertyMutationVariables
  >(DeleteSubPropertyDocument, options);
}
export type DeleteSubPropertyMutationHookResult = ReturnType<
  typeof useDeleteSubPropertyMutation
>;
export type DeleteSubPropertyMutationResult =
  ApolloReactCommon.MutationResult<DeleteSubPropertyMutation>;
export type DeleteSubPropertyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteSubPropertyMutation,
    DeleteSubPropertyMutationVariables
  >;
export const GetPropertyUsersDocument = gql`
  query getPropertyUsers($propertyID: String!) {
    getPropertyUsers(propertyID: $propertyID) {
      email
      first_name
      id
      is_authorized
      is_disabled
      last_name
      phone
      profile_url
      property_id
      reports_to
      role
      shift_end
      shift_start
      working_days
    }
  }
`;

/**
 * __useGetPropertyUsersQuery__
 *
 * To run a query within a React component, call `useGetPropertyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyUsersQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyUsersQuery,
    GetPropertyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyUsersQuery,
    GetPropertyUsersQueryVariables
  >(GetPropertyUsersDocument, options);
}
export function useGetPropertyUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyUsersQuery,
    GetPropertyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyUsersQuery,
    GetPropertyUsersQueryVariables
  >(GetPropertyUsersDocument, options);
}
export type GetPropertyUsersQueryHookResult = ReturnType<
  typeof useGetPropertyUsersQuery
>;
export type GetPropertyUsersLazyQueryHookResult = ReturnType<
  typeof useGetPropertyUsersLazyQuery
>;
export type GetPropertyUsersQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyUsersQuery,
  GetPropertyUsersQueryVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($propertyID: String!, $id: ID!) {
    deleteUser(propertyID: $propertyID, id: $id)
  }
`;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
