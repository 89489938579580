import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Loader from "../../components/Loader";

import useHotelInformation from "./hotel-info-hook";
import { Controller } from "react-hook-form";
import { useAuthProvider } from "../../core/authContext";
import { useGetUploadSignedUrlLazyQuery } from "../../generated/graphql";
import { CKTextEditor } from "../../components/Editor/CKEditor";

const HotelInformation = () => {
  const {
    register,
    handleSubmit,
    onSubmitMetaData,
    handleImage,
    setMetaValue,
    loading,
    metaWatch,
    hotelDetails,
    control,
    signInToken,
  } = useHotelInformation();
  const { getUser } = useAuthProvider();
  const property = getUser();
  const [getUploadSignedURL] = useGetUploadSignedUrlLazyQuery({
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box component={"form"} onSubmit={handleSubmit(onSubmitMetaData)}>
          <Typography variant="h4" align="center" fontWeight={600}>
            Hotel Information
          </Typography>

          <Grid container spacing={1} pt={2}>
            <Grid item md={4}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Hotel Name"
                {...register("hotel_name", {
                  required: "Hotel Name is required!",
                })}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Email"
                {...register("email")}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Phone"
                {...register("phone")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={1}>
            <Grid item md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      Boolean(metaWatch("has_order_service_id")) || false
                    }
                  />
                }
                {...register("has_order_service_id")}
                label="has Service ID"
              />
            </Grid>
            {metaWatch("has_order_service_id") && (
              <Grid item md={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Hotlync Order Service ID"
                  {...register("order_service_id")}
                />
              </Grid>
            )}

            <Grid item md={6}>
              <TextField
                fullWidth
                value={signInToken}
                // InputLabelProps={{ shrink: true }}
                label="Simphony Access Token"
                disabled
              />
            </Grid>
          </Grid>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Address
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.dir_con.address || ""}
              propertyId={property?.id}
              fieldName="address"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Directory Description
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.directory_description || ""}
              propertyId={property?.id}
              fieldName="directory_description"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Add Property Description
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.about.desc || ""}
              propertyId={property?.id}
              fieldName="description"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Promotion Description
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.promotion_desc || ""}
              propertyId={property?.id}
              fieldName="promotion_desc"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Feedback Description
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.feedback_desc || ""}
              propertyId={property?.id}
              fieldName="feedback_desc"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Art & Design Description
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.artNdesign?.desc || ""}
              propertyId={property?.id}
              fieldName="artNdesignDesc"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Neighbourhood Description
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.neighbourhood?.desc || ""}
              propertyId={property?.id}
              fieldName="neighbourhoodDesc"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Events Description
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.events?.desc || ""}
              propertyId={property?.id}
              fieldName="eventsDesc"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Safety N Security Description
            </Typography>
            <CKTextEditor
              initialValue={hotelDetails?.safetyNsecurity?.desc || ""}
              propertyId={property?.id}
              fieldName="safetyNsecurityDesc"
              setValue={setMetaValue}
              getUploadSignedURL={getUploadSignedURL}
            />
          </Stack>
          <Grid container spacing={1} pt={2}>
            <Grid container spacing={1} m={3} mt={1}>
              {/* About Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select About Image
                </Typography>
                <Box>
                  <img
                    alt="about img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("aboutImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update About
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "aboutImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "4px" }}
                  control={
                    <Controller
                      name={"about"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="about"
                />
              </Grid>
              {/* Select Direction Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Direction Image
                </Typography>
                <Box>
                  <img
                    alt="direction img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("directionImg") || "assets/defaultImg.jpg"}
                  />
                </Box>

                <Button variant="outlined" style={{ marginLeft: "-10px" }}>
                  <label>
                    Update Direction
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "directionImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  control={
                    <Controller
                      name={"directory"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="directory"
                />
              </Grid>

              {/* Select Direction Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Dining Image
                </Typography>
                <Box>
                  <img
                    alt="dining img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("diningImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Dining
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "diningImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "4px" }}
                  control={
                    <Controller
                      name={"dining"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="dining"
                />
              </Grid>

              {/* Select Hotel Service Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Service Image
                </Typography>
                <Box>
                  <img
                    alt="service img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("serviceImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Service
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "serviceImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  control={
                    <Controller
                      name={"hotel_services"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="services"
                />
              </Grid>

              <Grid item lg={2} md={4}>
                {/* <Grid item md={3} mb={2}> */}
                <Typography color="text.secondary" gutterBottom>
                  Select Rooms Image
                </Typography>
                <Box>
                  <img
                    alt="room img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("roomsImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Room
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "roomsImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "5px" }}
                  control={
                    <Controller
                      name={"hotel_rooms"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="rooms"
                />
              </Grid>
              <Grid item lg={2} md={4}>
                {/* <Grid item md={3} mb={2}> */}
                <Typography color="text.secondary" gutterBottom>
                  Select Art & Design Image
                </Typography>
                <Box>
                  <img
                    alt="room img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("artNdesignImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Art & Design
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "artNdesignImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "5px" }}
                  control={
                    <Controller
                      name={"artNdesign"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="Art & Design"
                />
              </Grid>
              <Grid item lg={2} md={4}>
                {/* <Grid item md={3} mb={2}> */}
                <Typography color="text.secondary" gutterBottom>
                  Select Neighbourhood Image
                </Typography>
                <Box>
                  <img
                    alt="room img"
                    width={"150px"}
                    height={"100px"}
                    src={
                      metaWatch("neighbourhoodImg") || "assets/defaultImg.jpg"
                    }
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Neighbourhood
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "neighbourhoodImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "5px" }}
                  control={
                    <Controller
                      name={"neighbourhood"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="Neighbourhood"
                />
              </Grid>
              <Grid item lg={2} md={4}>
                {/* <Grid item md={3} mb={2}> */}
                <Typography color="text.secondary" gutterBottom>
                  Select Events Image
                </Typography>
                <Box>
                  <img
                    alt="room img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("eventsImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Events
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "eventsImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "5px" }}
                  control={
                    <Controller
                      name={"events"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="Events"
                />
              </Grid>
              <Grid item lg={2} md={4}>
                {/* <Grid item md={3} mb={2}> */}
                <Typography color="text.secondary" gutterBottom>
                  Select Safety & Security Image
                </Typography>
                <Box>
                  <img
                    alt="room img"
                    width={"150px"}
                    height={"100px"}
                    src={
                      metaWatch("safetyNsecurityImg") || "assets/defaultImg.jpg"
                    }
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Safety & Security
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "safetyNsecurityImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "5px" }}
                  control={
                    <Controller
                      name={"safetyNsecurity"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="Safety & Security"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} m={2}>
              <Stack direction="row" spacing={2} margin="auto">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  // disabled={!isDirty && isDisable}
                  loading={false}
                >
                  Update Data
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default HotelInformation;
